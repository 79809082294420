import React from 'react';
import Svg, { G, Path, Polyline, Circle, Line, Rect } from 'svgs';
import { colors } from '../../../app-theme';

const Iui = ({ size, fill }) => {
  return (
    <Svg height={size || 28} width={size || 28} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.35 117.29" fill="none" stroke={fill || colors.brandBlue} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
        <G id="a"/>
            <G id="b">
                <G id="c">
                    <G>
                        <G>
                            <Path d="M80.82,52.83l8.23-8.16c.96-.96,1.55-2.14,1.75-3.31l-13.75-13.96c-1.19,.18-2.41,.76-3.39,1.74l-5.18,5.14-5.83,5.78-4.98,4.93-5.34,5.29-5.34,5.3-4.93,4.89-9.87,9.77"/>
                            <Line x1="92.54" y1="25.58" x2="116.85" y2="1.5"/>
                            <Polyline points="45.05 83.25 32.17 70.25 25.07 63.09 30.65 57.55 34.57 61.5"/>
                            <Polyline points="31.95 78.31 5.92 103.86 13.23 111.24 39.03 85.59"/>
                            <Line x1="17.74" y1="115.79" x2="1.5" y2="99.4"/>
                            <Line x1="68.47" y1="34.28" x2="73.7" y2="39.56"/>
                            <Line x1="57.66" y1="45" x2="60.69" y2="48.05"/>
                            <Line x1="52.31" y1="50.29" x2="54.93" y2="52.93"/>
                            <Line x1="46.97" y1="55.58" x2="49.59" y2="58.22"/>
                            <Line x1="62.63" y1="40.07" x2="66.14" y2="43.61"/>
                            <Line x1="42.04" y1="60.47" x2="45.73" y2="64.2"/>
                            <Rect x="85.06" y="23.88" width="6.54" height="12.24" transform="translate(46.83 -53.54) rotate(44.82)"/>
                        </G>
                    <Circle cx="78.03" cy="85.8" r="28.44"/>
                    <Circle cx="78.03" cy="85.8" r="18.27" transform="translate(-37.81 80.31) rotate(-45)"/>
                    <G>
                        <Path d="M82.09,81.55c-2.24,2.35-5.96,2.43-8.31,.19-2.35-2.24-2.43-5.96-.19-8.31,2.24-2.35,5.96-2.43,8.31-.19,2.35,2.24,2.43,5.96,.19,8.31Z"/>
                        <Path d="M90.59,89.66c-2.24,2.35-5.96,2.43-8.31,.19-2.35-2.24-2.43-5.96-.19-8.31,2.24-2.35,5.96-2.43,8.31-.19,2.35,2.24,2.43,5.96,.19,8.31Z"/>
                        <Path d="M73.98,90.05c-2.24,2.35-5.96,2.43-8.31,.19-2.35-2.24-2.43-5.96-.19-8.31,2.24-2.35,5.96-2.43,8.31-.19,2.35,2.24,2.43,5.96,.19,8.31Z"/>
                        <Path d="M82.48,98.16c-2.24,2.35-5.96,2.43-8.31,.19-2.35-2.24-2.43-5.96-.19-8.31,2.24-2.35,5.96-2.43,8.31-.19,2.35,2.24,2.43,5.96,.19,8.31Z"/>
                    </G>
                </G>
            </G>
        </G>
    </Svg>

  );
};

export default Iui;
