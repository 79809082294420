import merge from './common/utils/merge';

const defaults = {
    validationMessages: {},
    apiUrl: 'http://localhost:9002/api/v1',
    auth: {
        AuthClientID: 1,
        AuthClientSecret: 'verysecret',
        redirectUri: 'http://localhost:3000/',
        postLogoutRedirectUri: 'http://localhost:3000/',
        scope: 'openid',
        responseType: 'code id_token token',
        idTokenKey: 'idToken',
        csrfTokenKey: 'X-XSRF-TOKEN',
        authId: 'AuthId',
        refreshTokenId: 'RefreshTokenId'
    },
    calendly: {
        baseUrl: 'https://calendly.com/synapse-dev',
        eventTypes: [
            {
                link: '/',
                name: 'Schedule a call',
            },
        ],
    },
    cdnUrl: 'http://localhost:9002/api/',
    imageUrl: 'http://localhost:9002/docs/images',
};

const environments = {
    development: {
        apiUrl: process.env.REACT_APP_API_URL || `http://localhost:9002/api/v1`,
        imageUrl: 'http://localhost:9002/docs',
    },
    test: {
        apiUrl: process.env.REACT_APP_API_URL || `http://localhost:9002/api/v1`,
        imageUrl: 'http://localhost:9002/docs',
    },
    staging: {
        apiUrl: process.env.REACT_APP_API_URL || 'https://recipients.staging.twesb.com/api/v1',
        cdnUrl: 'https://cdn.staging.theworldeggbank.com',
        imageUrl: 'https://recipients.staging.twesb.com/docs',
        calendly: {
            baseUrl: 'https://calendly.com/recipient-team',
        },
    },
    production: {
        apiUrl: process.env.REACT_APP_API_URL || 'https://recipients.twesb.com/api/v1',
        imageUrl: 'https://recipients.twesb.com/docs',
        calendly: {
            baseUrl: 'https://calendly.com/recipient-team',
        },
    },
};

export default merge(defaults, environments[process.env.REACT_APP_ENV]);
