import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import ChevronUp from '../../common/components/icons/chevron-up';
import ChevronDown from '../../common/components/icons/chevron-down';
import { colors } from '../../app-theme';

const DonorProfileOverview = ({ donor }) => {
  const [backgroundExpanded, setBackgroundExpanded] = useState(true);
  const [characteristicsExpanded, setCharacteristicsExpanded] = useState(true);
  const [currentTab, setCurrentTab] = useState('talents');
  const [descriptionExpanded, setDescriptionExpanded] = useState(true);

  const donorData = useMemo(() => donor.toJS(), [donor]);

  return (
    <div>
      <h5 onClick={() => setCharacteristicsExpanded(!characteristicsExpanded)}>
        Characteristics
        {characteristicsExpanded ? (
          <ChevronUp size={20} fill={colors.brandBlue} />
        ) : (
          <ChevronDown size={20} fill={colors.brandBlue} />
        )}
      </h5>
      {!characteristicsExpanded && <hr className="section-divider" />}
      {characteristicsExpanded && (
        <div className="donor-profile__content__expanded">
          <ul className="donor-profile__field-group">
            <li>
              <div className="donor-profile__field-label">Eye Color</div>
              <div className="donor-profile__field-value">{donorData.eye_color || 'N/A'}</div>
            </li>
            <li>
              <div className="donor-profile__field-label">Hair Color</div>
              <div className="donor-profile__field-value">{donorData.hair_color || 'N/A'}</div>
            </li>
            <li>
              <div className="donor-profile__field-label">Hair Texture</div>
              <div className="donor-profile__field-value">
                {donorData.hair_texture || 'N/A'}
              </div>
            </li>
            <li>
              <div className="donor-profile__field-label">Skin Type</div>
              <div>{donorData.skin_type || 'N/A'}</div>
            </li>
          </ul>
          <ul className="donor-profile__field-group">
            <li>
              <div className="donor-profile__field-label">Height</div>
              <div className="donor-profile__field-value">{donorData.height || 'N/A'}</div>
            </li>
            <li>
              <div className="donor-profile__field-label">Weight</div>
              <div className="donor-profile__field-value">{donorData.weight || 'N/A'}</div>
            </li>
            <li>
              <div className="donor-profile__field-label">Body Type</div>
              <div className="donor-profile__field-value">{donorData.body_type || 'N/A'}</div>
            </li>
            <li>
              <div className="donor-profile__field-label">Blood Type</div>
              <div className="donor-profile__field-value">{donorData.blood_type || 'N/A'}</div>
            </li>
          </ul>
          <ul className="donor-profile__field-group">
            <li>
              <div className="donor-profile__field-label">Race</div>
              <div className="donor-profile__field-value">{donorData.ethnicity || 'N/A'}</div>
            </li>
            <li>
              <div className="donor-profile__field-label">Mother's Ancestry</div>
              <div className="donor-profile__field-value">
                {donorData.ancestry_mother?.length ? donorData.ancestry_mother.join(', ') : 'N/A'}
              </div>
            </li>
            <li>
              <div className="donor-profile__field-label">Father's Ancestry</div>
              <div className="donor-profile__field-value">
                {donorData.ancestry_father?.length ? donorData.ancestry_father.join(', ') : 'N/A'}
              </div>
            </li>
            <li>
              <div className="donor-profile__field-label">Rh Factor</div>
              <div className="donor-profile__field-value">{donorData.blood_type_rh || 'N/A'}</div>
            </li>
          </ul>
        </div>
      )}
      <h5 onClick={() => setBackgroundExpanded(!backgroundExpanded)}>
        Donor Background
        {backgroundExpanded ? (
          <ChevronUp size={20} fill={colors.brandBlue} />
        ) : (
          <ChevronDown size={20} fill={colors.brandBlue} />
        )}
      </h5>
      {!backgroundExpanded && <hr className="section-divider" />}
      {backgroundExpanded && (
        <div className="donor-profile__content__expanded">
          <ul className="donor-profile__field-group">
            <li>
              <div className="donor-profile__field-label">State/Province</div>
              <div className="donor-profile__field-value">{donorData.state_or_province || 'N/A'}</div>
            </li>
            <li>
              <div className="donor-profile__field-label">Country</div>
              <div className="donor-profile__field-value">{donorData.country || 'N/A'}</div>
            </li>
          </ul>
          <ul className="donor-profile__field-group">
            <li>
              <div className="donor-profile__field-label">Education Level</div>
              <div className="donor-profile__field-value">{donorData.education_level_public || 'N/A'}</div>
            </li>
            <li>
              <div className="donor-profile__field-label">School GPA</div>
              <div className="donor-profile__field-value">
                { donorData.gpa || 'N/A'}
              </div>
            </li>
          </ul>
          <ul className="donor-profile__field-group">
            <li>
              <div className="donor-profile__field-label">Occupation</div>
              <div className="donor-profile__field-value">{donorData.occupation || 'N/A'}</div>
            </li>
          </ul>
        </div>
      )}
      <h5 onClick={() => setDescriptionExpanded(!descriptionExpanded)}>
        Donor Description
        {descriptionExpanded ? (
          <ChevronUp size={20} fill={colors.brandBlue} />
        ) : (
          <ChevronDown size={20} fill={colors.brandBlue} />
        )}
      </h5>
      {descriptionExpanded && (
        <div className="donor-profile__tabs-vertical">
          <nav>
            <ul>
              <li className={cn({ active: currentTab === 'talents' })} onClick={() => setCurrentTab('talents')}>
                Talents
              </li>
              <li className={cn({ active: currentTab === 'character' })} onClick={() => setCurrentTab('character')}>
                Character
              </li>
              <li className={cn({ active: currentTab === 'favorite' })} onClick={() => setCurrentTab('favorite')}>
                Favorite
              </li>
              <li className={cn({ active: currentTab === 'goals' })} onClick={() => setCurrentTab('goals')}>
                Goals
              </li>
              <li className={cn({ active: currentTab === 'interest' })} onClick={() => setCurrentTab('interest')}>
                Interest
              </li>
              <li className={cn({ active: currentTab === 'exercise' })} onClick={() => setCurrentTab('exercise')}>
                Exercise
              </li>
            </ul>
          </nav>
          <div className="donor-profile__tabs-vertical__content">
            <h5>{currentTab.charAt(0).toUpperCase() + currentTab.slice(1)}</h5>
            <p>{donorData[currentTab] || 'N/A'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DonorProfileOverview;
