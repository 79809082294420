import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import InputScaffold from './InputScaffold';

const Textarea = forwardRef(({ children, className, disabled, label, placeholder, rounded, ...props }, ref) => {
  const [field, meta] = useField({ disabled, ...props });

  return (
    <InputScaffold
      className={className}
      disabled={disabled}
      error={meta.error}
      label={label}
      name={field.name}
      touched={meta.touched}
    >
      <textarea
        className={rounded && 'input-rounded'}
        disabled={disabled}
        {...field}
        {...props}
        placeholder={placeholder}
        ref={ref}
      />
      {children}
    </InputScaffold>
  );
});

Textarea.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Textarea.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  label: null,
};

export default Textarea;
