import React from 'react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import Input from '../../common/forms/Input';
import { useQuery } from '../../common/utils/hooks';
import Button from '../../common/components/buttons/button';

const DonorSearchButton = () => {
  const [query, setQuery] = useQuery();

  const loading = useSelector(({ donors: { list } }) => list.get('loading'));

  return (
    <div className="donor-search__search-btn">
      <Formik
        enableReinitialize
        initialValues={{ donor_id: query.get('id', '') }}
        onSubmit={({ donor_id }) => {
          if (donor_id) {
            setQuery(query.set('id', donor_id).delete('from'));
          } else {
            setQuery(query.delete('id').delete('from'));
          }
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="donor-search__form-container">
              <Input
                name="donor_id"
                type="text"
                maxLength={8}
                onBlur={e => {
                  if (!e.target.value) {
                    setQuery(query.delete('id').delete('from'));
                  }
                }}
                onChange={e => {
                  if (/^\d*$/.test(e.target.value)) {
                    setFieldValue('donor_id', e.target.value);
                  }
                }}
                placeholder="Enter a Donor ID number"
                rounded
              >
                {Boolean(query.get('id')) && (
                  <div className="donor-search__clear-btn" onClick={() => setQuery(query.delete('id').delete('from'))}>
                    Clear
                  </div>
                )}
              </Input>
              <Button type="submit" disabled={loading}>
                Search
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DonorSearchButton;
