import client from '../client';

export const GET_RECIPIENT = 'GET_RECIPIENT';
export const GET_RECIPIENT_SUCCESS= 'GET_RECIPIENT_SUCCESS';

export const getRecipient = (recipientId) => {
    return ({
        type: GET_RECIPIENT,
        promise: client.get(`recipients-auth/${recipientId}`),
    });
}
