import React, { useState } from 'react';

const HoverNavMenu = ({ label, children }) => {
  const [isHovering, setHovering] = useState(false);

  return (
    <div className="link__withDropdown" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <span>{label}</span>
      {isHovering ? <div className="navigation__dropdown">{children}</div> : null}
    </div>
  );
};

export default HoverNavMenu;
