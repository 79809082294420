import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from 'synfrastructure';
import cn from 'classnames';

import ContactInformation from './ContactInformation';
import ScheduleAppointment from './ScheduleAppointment';
import ContactUsForm from './ContactUsForm';

import Close from '../../common/components/icons/close';
import LeftArrow from '../../common/components/icons/left-arrow';
import Calendar from '../../common/components/icons/calendar';
import Button from '../../common/components/buttons/button';
import { colors } from '../../app-theme';

const Contact = () => {
  const [contactUsModalVisible, setContactUsModalVisible] = useState(false);
  const deleted = useSelector(({ user }) => user.getIn(['data', 'deleted']));
  const recipientId = useSelector(({ user }) => user.getIn(['data', 'legacy_user_id']));
  const isMobile = useSelector(({ browser }) => browser.lessThan.large);

  return (
    <div className="contact-page">
      <Modal
        closeButton={
          <span role="img" aria-label="Close Modal">
            <Close size={40} />
          </span>
        }
        isVisible={contactUsModalVisible}
        onClose={() => setContactUsModalVisible(false)}
      >
        <h3 className="modal__header">Contact Us</h3>
        <div className="modal__content">
          <ContactUsForm onClose={() => setContactUsModalVisible(false)} isVisible={contactUsModalVisible} />
        </div>
      </Modal>
      {!deleted && (
        <div className="content__navigation margin-bottom__xl">
          <Link className="button--text button--has-icon" to="/">
            <LeftArrow /> Back to Donor Search
          </Link>

          {recipientId && (
            <div className="recipient-id">
              <strong>Your recipient ID: {recipientId}</strong>
            </div>
          )}
        </div>
      )}
      <h2 className="brandBlue margin-bottom__s">Contact The World Egg Bank</h2>
      <div className={cn({ 'layout__columns-2': !isMobile })}>
        <div className="layout__column-left">
          <ContactInformation />
          <div className="margin-top__m">
            <Button
              onClick={() => {
                setContactUsModalVisible(true);
              }}
            >
              Contact Us
            </Button>
          </div>
        </div>
        <div className={cn('layout__column-right', { 'margin-top__m': isMobile })}>
          {!deleted && (
            <div className="schedule__card">
              <h3 className="brandBlue margin-bottom__s">Schedule Appointment</h3>
              <ScheduleAppointment />
              <div className="deco-icon">
                <Calendar size={72} fill={colors.lightGray} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
