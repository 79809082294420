import { isNull, isUndefined } from 'lodash';

import client from '../client';

export const GET_DONOR_PROFILE = 'GET_DONOR_PROFILE';
export const GET_DONOR_PROFILE_REQUEST = 'GET_DONOR_PROFILE_REQUEST';
export const GET_DONOR_PROFILE_SUCCESS = 'GET_DONOR_PROFILE_SUCCESS';
export const GET_DONOR_PROFILE_FAILURE = 'GET_DONOR_PROFILE_FAILURE';

export const SEARCH_DONORS = 'SEARCH_DONORS';
export const SEARCH_DONORS_REQUEST = 'SEARCH_DONORS_REQUEST';
export const SEARCH_DONORS_SUCCESS = 'SEARCH_DONORS_SUCCESS';
export const SEARCH_DONORS_FAILURE = 'SEARCH_DONORS_FAILURE';
export const SET_DONOR_PROFILE_ID = 'SET_DONOR_PROFILE_ID';
export const SET_LIGHTBOX_PHOTO_ID = 'SET_LIGHTBOX_PHOTO_ID';

export const GET_DONOR_GENETIC_REPORT = 'GET_DONOR_GENETIC_REPORT';
export const GET_DONOR_GENETIC_REPORT_REQUEST = 'GET_DONOR_GENETIC_REPORT_REQUEST';
export const GET_DONOR_GENETIC_REPORT_SUCCESS = 'GET_DONOR_GENETIC_REPORT_SUCCESS';
export const GET_DONOR_GENETIC_REPORT_FAILURE = 'GET_DONOR_GENETIC_REPORT_FAILURE';

export const GET_DONOR_DETAILS_REPORT = 'GET_DONOR_DETAILS_REPORT';
export const GET_DONOR_DETAILS_REPORT_REQUEST = 'GET_DONOR_DETAILS_REPORT_REQUEST';
export const GET_DONOR_DETAILS_REPORT_SUCCESS = 'GET_DONOR_DETAILS_REPORT_SUCCESS';
export const GET_DONOR_DETAILS_REPORT_FAILURE = 'GET_DONOR_DETAILS_REPORT_FAILURE';

const VALID_QUERY = {
    FAVORITES: ['from', 'option'],
    SEARCH: [
        'ancestry',
        'available_in',
        'blood_type',
        'blood_type_rh',
        'eye_color',
        'from',
        'hair_color',
        'hair_texture',
        'height',
        'id',
        'min_height',
        'max_height',
        'option',
        'donor_type',
        'race',
        'cmv_status',
    ],
};

export const getDonorProfilePublic = (id: number) => ({
    type: GET_DONOR_PROFILE,
    promise: client.get(`donors/${id}`),
});

export const getDonorProfile = (id: number) => ({
    promise: client.get(`donor-details/${id}`),
    type: GET_DONOR_PROFILE,
    data: [id],
});

export const getDonorGeneticReport = (donorId: number, docId: number) => ({
    promise: client.fetch(`donor-details/${donorId}/document/${docId}`),
    type: GET_DONOR_GENETIC_REPORT,
    data: [donorId, docId],
});

export const getDonorDetailsPdf = (donorId: number) => ({
    promise: client.get(`donor-details/${donorId}/details-pdf`),
    type: GET_DONOR_DETAILS_REPORT,
    data: [donorId]
});

export const searchDonors = (query: any) => {
    const params = query.filter((value: any, key: any) => VALID_QUERY.SEARCH.includes(key) && !isNull(value) && !isUndefined(value)).toJS();

    return {
        type: SEARCH_DONORS,
        promise: client.get('donors/search', params),
        from: query.get('from'),
    };
};

export const searchDonorsAuth = (query: any) => {
    const params = query.filter((value: any, key: any) => VALID_QUERY.SEARCH.includes(key) && !isNull(value) && !isUndefined(value)).toJS();

    return {
        type: SEARCH_DONORS,
        promise: client.get('donors-auth/search', params),
        from: query.get('from'),
        data: [query],
    };
};

// Used to retry requests on token refresh
// not needed for public endpoints
// maps action type to dispatch function
export const DonorActionMap = {
    GET_DONOR_PROFILE: getDonorProfile,
    GET_DONOR_GENETIC_REPORT: getDonorGeneticReport,
    GET_DONOR_DETAILS_REPORT: getDonorDetailsPdf,
    SEARCH_DONORS: searchDonorsAuth,
};
