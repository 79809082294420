import { Form, Formik } from 'formik';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { typography, spacing } from '../../app-theme';
import StyledButton from '../../common/components/buttons/button';
import Input from '../../common/forms/Input';
import { object, string, number } from 'yup';
import { resetPassword, RESET_PASSWORD_SUCCESS } from '../../redux/user-actions';
import { useDispatch } from 'react-redux';
const StyledInput = Input as any;

const styles = {
    h2: {
        ...typography.displayL,
        marginBottom: spacing.u4,
    },
};

const queryParamSchema = object({
    authUserId: number().required(),
    resetKey: string().required(),
});

const SCHEMA = object().shape({
    confirmation: string().test('passwords-match', 'Passwords must match', function(value) {
        return value === this.parent.password;
    }),
    password: string()
        .min(8, 'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter.')
        .matches(/[A-Z]/, 'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter.')
        .matches(/[a-z]/, 'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter.')
        .matches(/\d/, 'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter.')
        .required('Please enter a password'),
});

export const ResetPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const queryParams = { authUserId: parseInt(query.get('userId') || '', 10), resetKey: query.get('resetKey') as string };
    if (!queryParamSchema.isValidSync(queryParams)) {
        history.replace('');
    }

    return (
        <>
            <div>
                <div className="auth-layout__form-container">
                    <h2 style={styles.h2}>Reset Password</h2>
                    {queryParams && (
                        <Formik
                            initialValues={{ password: '', confirmation: '', ...queryParams }}
                            onSubmit={async (values) => {
                                const resp = (await dispatch(resetPassword(values))) as any;
                                if (resp.type === RESET_PASSWORD_SUCCESS) {
                                    return history.replace('');
                                }

                                return;
                            }}
                            validationSchema={SCHEMA.concat(queryParamSchema.required())}
                        >
                            {({ isSubmitting, isValid }) => (
                                <Form>
                                    <StyledInput
                                        disabled={isSubmitting}
                                        id="password"
                                        name="password"
                                        label="Password"
                                        placeholder="Password"
                                        type="password"
                                        rounded
                                    />
                                    <StyledInput
                                        disabled={isSubmitting}
                                        id="confirmation"
                                        name="confirmation"
                                        label="Confirm Password"
                                        placeholder="Confirm Password"
                                        type="password"
                                        rounded
                                    />

                                    <StyledButton type="submit" buttonType="primary" disabled={isSubmitting || !isValid}>
                                        Submit
                                    </StyledButton>
                                </Form>
                            )}
                        </Formik>
                    )}

                    <p className="margin-top__s">
                        <Link to="/login">Back to login</Link>
                    </p>
                </div>
            </div>
        </>
    );
};
