import React from 'react';
import Svg, { Path } from 'svgs';

const Minus = ({ fill, size }) => {
  return (
    <Svg height={size || 24} width={size || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <Path fill={fill || '#FFFFFF'} d="M19 13H5a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2z" />
    </Svg>
  );
};

export default Minus;
