import client from '../client';
import config from '../config';
import store from 'store2';
import { ThunkActionDispatch } from 'redux-thunk';

enum ActionTypeSuffix {
    REQUEST = 'REQUEST',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    ERROR = 'ERROR',
}

export const AuthConstants = { AuthClientSecret: config.auth.AuthClientSecret as string, AuthClientId: config.auth.AuthClientID as number };

// builds strongly typed action types following the same principles as the client middleware
const createActionTypes = <T extends string>(typeBase: T) => Object
    .values(ActionTypeSuffix)
    .reduce((acc, curr) => ({ ...acc, [`${typeBase}_${curr}`]: `${typeBase}_${curr}` }), {}) as
    {[key in `${T}_${ActionTypeSuffix}`]: string};

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CHECK_USER_LOGGED_IN = 'CHECK_USER_LOGGED_IN';
const VERIFY_EMAIL = 'VERIFY_EMAIL';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const RESET_PASSWORD = 'RESET_PASSWORD';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';


export const { RESET_PASSWORD_ERROR, RESET_PASSWORD_FAILURE, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS } = createActionTypes(RESET_PASSWORD);
export const { VERIFY_EMAIL_ERROR, VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILURE } = createActionTypes(VERIFY_EMAIL);
export const { LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_ERROR, LOGIN_USER_FAILURE } = createActionTypes(LOGIN_USER);
export const { FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS } = createActionTypes(FORGOT_PASSWORD);
export const { REFRESH_TOKEN_ERROR, REFRESH_TOKEN_FAILURE, REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS } = createActionTypes(REFRESH_TOKEN);

interface ILoginResult {
    AuthId: number;
    ExpiresUtc: Date;
    IssuedUtc: Date;
    Jwt: string;
    CsrfToken: string;
    RefreshTokenIdentifier: string;
    ClaimFlags: any;
}

export const setLoginParams = (loginResult: ILoginResult) => {
    store.set(config.auth.refreshTokenId, loginResult.RefreshTokenIdentifier);
    store.set(config.auth.authId, loginResult.AuthId);
    store.set(config.auth.idTokenKey, loginResult.Jwt);
    store.set(config.auth.csrfTokenKey, loginResult.CsrfToken);
}

export const getUser = () => ({
    promise: client.get('users/user'),
    type: GET_USER,
});

export const registerUser = (values: any) => ({
    promise: client.post('register', values),
    type: REGISTER_USER,
});

export const verifyUserEmail = (data: { ValidationKey: string, UserId: number }) => async (dispatch: ThunkActionDispatch<any>) => {
    const resp = await dispatch({
        promise: client.post('authusers/verifyEmail', {...data, ...AuthConstants }),
        type: VERIFY_EMAIL,
    });

    if (resp?.response?.ok) {
        setLoginParams(resp.json.LoginResult);
        dispatch(getUser());
    }

    return resp;
};

export const userLogout = () => {
    store.clearAll();
    return {
        type: LOGOUT_USER,
    };
};

export const getToken = (data: { Username: string, Password: string }) => async (dispatch: ThunkActionDispatch<any>) => {
    const resp = await dispatch({
        promise: client.post('authusers/token', {...data, ...AuthConstants }),
        type: LOGIN_USER,
    });

    if (resp?.response?.ok) {
        setLoginParams(resp.json.LoginResult);
        dispatch(getUser());
    }

    return resp;
};

export const forgotPassword = (Email: string): any => ({promise: client.post('users/forgot', { Email }), type: FORGOT_PASSWORD })

interface IResetPasswordParams {
    authClientId: number;
    authClientSecret: string;
    authUserId: number;
    confirmation: string;
    password: string;
    resetKey: string;
}

export type IResetPasswordArgs = Omit<IResetPasswordParams, "authClientSecret" | "authClientId">

export const resetPassword = (params: IResetPasswordArgs) => async (dispatch: ThunkActionDispatch<any>) => {
    const resp = await dispatch({promise: client.post('authUsers/reset', {...params, ...AuthConstants }), type: RESET_PASSWORD });

    if (resp?.response?.ok) {
        setLoginParams(resp.json.LoginResult);
        dispatch(getUser());
    }

    return resp;
};

export const refreshToken = async () => {
    const refreshParams = {
        AuthClientId: AuthConstants.AuthClientId,
        AuthClientSecret: AuthConstants.AuthClientSecret,
        AuthUserId: store.get(config.auth.authId),
        TokenIdentifier: store.get(config.auth.refreshTokenId)
    }

    const resp = await client.post('authusers/refresh', refreshParams);
    if (resp?.ok) {
        const json = await resp.json();
        setLoginParams(json.LoginResult);
        return resp?.ok;
    } else {
        return false;
    }
};

