import React from 'react';
import Svg, { G, Rect } from 'svgs';

const Menu = ({ size, fill }) => {
  return (
    <Svg height={size || 24} width={size || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <G>
        <Rect fill={fill || '#FFF'} x="3" y="11" width="18" height="2" rx=".95" ry=".95" />
        <Rect fill={fill || '#FFF'} x="3" y="16" width="18" height="2" rx=".95" ry=".95" />
        <Rect fill={fill || '#FFF'} x="3" y="6" width="18" height="2" rx=".95" ry=".95" />
      </G>
    </Svg>
  );
};

export default Menu;
