import localstorage from 'store2';
import uuid from 'uuid';
import config from '../../config';

export function getLoginUrl() {
  const nonce = uuid();
  localstorage.set('nonce', nonce);
  return (
    `${config.auth.domain}/op/auth?client_id=${config.auth.clientId}` +
    `&response_type=${config.auth.responseType}&scope=${config.auth.scope}` +
    `&redirect_uri=${config.auth.redirectUri}&nonce=${nonce}`
  );
}

export function getRegisterUrl() {
  localstorage.clearAll();
  const nonce = uuid();
  localstorage.set('nonce', nonce);
  return (
    `${config.auth.domain}/user/register?client_id=${config.auth.clientId}` +
    `&response_type=${config.auth.responseType}&scope=${config.auth.scope}` +
    `&redirect_uri=${config.auth.redirectUri}&nonce=${nonce}`
  );
}

export function getEmailSettingsUrl() {
  return (
    `${config.auth.domain}/user/email-settings?client_id=${config.auth.clientId}` +
    `&redirect_uri=${config.auth.redirectUri}`
  );
}

export function getChangePasswordUrl() {
  return `${config.auth.domain}/user/password?client_id=${config.auth.clientId}&redirect_uri=${config.auth.redirectUri}`;
}
