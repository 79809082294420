import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TextButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    destructive: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    const { className, children, destructive, onClick, ...passthrough } = this.props;

    return (
      <button onClick={onClick} className={classNames('button--text type__primary', className)} {...passthrough}>
        <div>{children}</div>
      </button>
    );
  }
}

export default TextButton;
