
export async function getCountriesList() {
  return [
    { CountryCode: 'US', Name: 'United States' },
    { CountryCode: 'AF', Name: 'Afghanistan' },
    { CountryCode: 'AL', Name: 'Albania' },
    { CountryCode: 'DZ', Name: 'Algeria' },
    { CountryCode: 'AS', Name: 'American Samoa' },
    { CountryCode: 'AD', Name: 'Andorra' },
    { CountryCode: 'AO', Name: 'Angola' },
    { CountryCode: 'AI', Name: 'Anguilla' },
    { CountryCode: 'AQ', Name: 'Antarctica' },
    { CountryCode: 'AG', Name: 'Antigua and Barbuda' },
    { CountryCode: 'AR', Name: 'Argentina' },
    { CountryCode: 'AM', Name: 'Armenia' },
    { CountryCode: 'AW', Name: 'Aruba' },
    { CountryCode: 'AU', Name: 'Australia' },
    { CountryCode: 'AT', Name: 'Austria' },
    { CountryCode: 'AZ', Name: 'Azerbaijan' },
    { CountryCode: 'BS', Name: 'Bahamas' },
    { CountryCode: 'BH', Name: 'Bahrain' },
    { CountryCode: 'BD', Name: 'Bangladesh' },
    { CountryCode: 'BB', Name: 'Barbados' },
    { CountryCode: 'BY', Name: 'Belarus' },
    { CountryCode: 'BE', Name: 'Belgium' },
    { CountryCode: 'BZ', Name: 'Belize' },
    { CountryCode: 'BJ', Name: 'Benin' },
    { CountryCode: 'BM', Name: 'Bermuda' },
    { CountryCode: 'BT', Name: 'Bhutan' },
    { CountryCode: 'BO', Name: 'Bolivia' },
    { CountryCode: 'BA', Name: 'Bosnia and Herzegovina' },
    { CountryCode: 'BW', Name: 'Botswana' },
    { CountryCode: 'BR', Name: 'Brazil' },
    { CountryCode: 'IO', Name: 'British Indian Ocean Territory' },
    { CountryCode: 'VG', Name: 'British Virgin Islands' },
    { CountryCode: 'BN', Name: 'Brunei' },
    { CountryCode: 'BG', Name: 'Bulgaria' },
    { CountryCode: 'BF', Name: 'Burkina Faso' },
    { CountryCode: 'BI', Name: 'Burundi' },
    { CountryCode: 'KH', Name: 'Cambodia' },
    { CountryCode: 'CM', Name: 'Cameroon' },
    { CountryCode: 'CA', Name: 'Canada' },
    { CountryCode: 'CV', Name: 'Cape Verde' },
    { CountryCode: 'KY', Name: 'Cayman Islands' },
    { CountryCode: 'CF', Name: 'Central African Republic' },
    { CountryCode: 'TD', Name: 'Chad' },
    { CountryCode: 'CL', Name: 'Chile' },
    { CountryCode: 'CN', Name: 'China' },
    { CountryCode: 'CX', Name: 'Christmas Island' },
    { CountryCode: 'CC', Name: 'Cocos Islands' },
    { CountryCode: 'CO', Name: 'Colombia' },
    { CountryCode: 'KM', Name: 'Comoros' },
    { CountryCode: 'CK', Name: 'Cook Islands' },
    { CountryCode: 'CR', Name: 'Costa Rica' },
    { CountryCode: 'HR', Name: 'Croatia' },
    { CountryCode: 'CU', Name: 'Cuba' },
    { CountryCode: 'CW', Name: 'Curacao' },
    { CountryCode: 'CY', Name: 'Cyprus' },
    { CountryCode: 'CZ', Name: 'Czech Republic' },
    { CountryCode: 'CD', Name: 'Democratic Republic of the Congo' },
    { CountryCode: 'DK', Name: 'Denmark' },
    { CountryCode: 'DJ', Name: 'Djibouti' },
    { CountryCode: 'DM', Name: 'Dominica' },
    { CountryCode: 'DO', Name: 'Dominican Republic' },
    { CountryCode: 'TL', Name: 'East Timor' },
    { CountryCode: 'EC', Name: 'Ecuador' },
    { CountryCode: 'EG', Name: 'Egypt' },
    { CountryCode: 'SV', Name: 'El Salvador' },
    { CountryCode: 'GQ', Name: 'Equatorial Guinea' },
    { CountryCode: 'ER', Name: 'Eritrea' },
    { CountryCode: 'EE', Name: 'Estonia' },
    { CountryCode: 'ET', Name: 'Ethiopia' },
    { CountryCode: 'FK', Name: 'Falkland Islands' },
    { CountryCode: 'FO', Name: 'Faroe Islands' },
    { CountryCode: 'FJ', Name: 'Fiji' },
    { CountryCode: 'FI', Name: 'Finland' },
    { CountryCode: 'FR', Name: 'France' },
    { CountryCode: 'PF', Name: 'French Polynesia' },
    { CountryCode: 'GA', Name: 'Gabon' },
    { CountryCode: 'GM', Name: 'Gambia' },
    { CountryCode: 'GE', Name: 'Georgia' },
    { CountryCode: 'DE', Name: 'Germany' },
    { CountryCode: 'GH', Name: 'Ghana' },
    { CountryCode: 'GI', Name: 'Gibraltar' },
    { CountryCode: 'GR', Name: 'Greece' },
    { CountryCode: 'GL', Name: 'Greenland' },
    { CountryCode: 'GD', Name: 'Grenada' },
    { CountryCode: 'GU', Name: 'Guam' },
    { CountryCode: 'GT', Name: 'Guatemala' },
    { CountryCode: 'GG', Name: 'Guernsey' },
    { CountryCode: 'GN', Name: 'Guinea' },
    { CountryCode: 'GW', Name: 'Guinea-Bissau' },
    { CountryCode: 'GY', Name: 'Guyana' },
    { CountryCode: 'HT', Name: 'Haiti' },
    { CountryCode: 'HN', Name: 'Honduras' },
    { CountryCode: 'HK', Name: 'Hong Kong' },
    { CountryCode: 'HU', Name: 'Hungary' },
    { CountryCode: 'IS', Name: 'Iceland' },
    { CountryCode: 'IN', Name: 'India' },
    { CountryCode: 'ID', Name: 'Indonesia' },
    { CountryCode: 'IR', Name: 'Iran' },
    { CountryCode: 'IQ', Name: 'Iraq' },
    { CountryCode: 'IE', Name: 'Ireland' },
    { CountryCode: 'IM', Name: 'Isle of Man' },
    { CountryCode: 'IL', Name: 'Israel' },
    { CountryCode: 'IT', Name: 'Italy' },
    { CountryCode: 'CI', Name: 'Ivory Coast' },
    { CountryCode: 'JM', Name: 'Jamaica' },
    { CountryCode: 'JP', Name: 'Japan' },
    { CountryCode: 'JE', Name: 'Jersey' },
    { CountryCode: 'JO', Name: 'Jordan' },
    { CountryCode: 'KZ', Name: 'Kazakhstan' },
    { CountryCode: 'KE', Name: 'Kenya' },
    { CountryCode: 'KI', Name: 'Kiribati' },
    { CountryCode: 'XK', Name: 'Kosovo' },
    { CountryCode: 'KW', Name: 'Kuwait' },
    { CountryCode: 'KG', Name: 'Kyrgyzstan' },
    { CountryCode: 'LA', Name: 'Laos' },
    { CountryCode: 'LV', Name: 'Latvia' },
    { CountryCode: 'LB', Name: 'Lebanon' },
    { CountryCode: 'LS', Name: 'Lesotho' },
    { CountryCode: 'LR', Name: 'Liberia' },
    { CountryCode: 'LY', Name: 'Libya' },
    { CountryCode: 'LI', Name: 'Liechtenstein' },
    { CountryCode: 'LT', Name: 'Lithuania' },
    { CountryCode: 'LU', Name: 'Luxembourg' },
    { CountryCode: 'MO', Name: 'Macau' },
    { CountryCode: 'MK', Name: 'Macedonia' },
    { CountryCode: 'MG', Name: 'Madagascar' },
    { CountryCode: 'MW', Name: 'Malawi' },
    { CountryCode: 'MY', Name: 'Malaysia' },
    { CountryCode: 'MV', Name: 'Maldives' },
    { CountryCode: 'ML', Name: 'Mali' },
    { CountryCode: 'MT', Name: 'Malta' },
    { CountryCode: 'MH', Name: 'Marshall Islands' },
    { CountryCode: 'MR', Name: 'Mauritania' },
    { CountryCode: 'MU', Name: 'Mauritius' },
    { CountryCode: 'YT', Name: 'Mayotte' },
    { CountryCode: 'MX', Name: 'Mexico' },
    { CountryCode: 'FM', Name: 'Micronesia' },
    { CountryCode: 'MD', Name: 'Moldova' },
    { CountryCode: 'MC', Name: 'Monaco' },
    { CountryCode: 'MN', Name: 'Mongolia' },
    { CountryCode: 'ME', Name: 'Montenegro' },
    { CountryCode: 'MS', Name: 'Montserrat' },
    { CountryCode: 'MA', Name: 'Morocco' },
    { CountryCode: 'MZ', Name: 'Mozambique' },
    { CountryCode: 'MM', Name: 'Myanmar' },
    { CountryCode: 'NA', Name: 'Namibia' },
    { CountryCode: 'NR', Name: 'Nauru' },
    { CountryCode: 'NP', Name: 'Nepal' },
    { CountryCode: 'NL', Name: 'Netherlands' },
    { CountryCode: 'AN', Name: 'Netherlands Antilles' },
    { CountryCode: 'NC', Name: 'New Caledonia' },
    { CountryCode: 'NZ', Name: 'New Zealand' },
    { CountryCode: 'NI', Name: 'Nicaragua' },
    { CountryCode: 'NE', Name: 'Niger' },
    { CountryCode: 'NG', Name: 'Nigeria' },
    { CountryCode: 'NU', Name: 'Niue' },
    { CountryCode: 'KP', Name: 'North Korea' },
    { CountryCode: 'MP', Name: 'Northern Mariana Islands' },
    { CountryCode: 'NO', Name: 'Norway' },
    { CountryCode: 'OM', Name: 'Oman' },
    { CountryCode: 'PK', Name: 'Pakistan' },
    { CountryCode: 'PW', Name: 'Palau' },
    { CountryCode: 'PS', Name: 'Palestine' },
    { CountryCode: 'PA', Name: 'Panama' },
    { CountryCode: 'PG', Name: 'Papua New Guinea' },
    { CountryCode: 'PY', Name: 'Paraguay' },
    { CountryCode: 'PE', Name: 'Peru' },
    { CountryCode: 'PH', Name: 'Philippines' },
    { CountryCode: 'PN', Name: 'Pitcairn' },
    { CountryCode: 'PL', Name: 'Poland' },
    { CountryCode: 'PT', Name: 'Portugal' },
    { CountryCode: 'PR', Name: 'Puerto Rico' },
    { CountryCode: 'QA', Name: 'Qatar' },
    { CountryCode: 'CG', Name: 'Republic of the Congo' },
    { CountryCode: 'RE', Name: 'Reunion' },
    { CountryCode: 'RO', Name: 'Romania' },
    { CountryCode: 'RU', Name: 'Russia' },
    { CountryCode: 'RW', Name: 'Rwanda' },
    { CountryCode: 'BL', Name: 'Saint Barthelemy' },
    { CountryCode: 'SH', Name: 'Saint Helena' },
    { CountryCode: 'KN', Name: 'Saint Kitts and Nevis' },
    { CountryCode: 'LC', Name: 'Saint Lucia' },
    { CountryCode: 'MF', Name: 'Saint Martin' },
    { CountryCode: 'PM', Name: 'Saint Pierre and Miquelon' },
    { CountryCode: 'VC', Name: 'Saint Vincent and the Grenadines' },
    { CountryCode: 'WS', Name: 'Samoa' },
    { CountryCode: 'SM', Name: 'San Marino' },
    { CountryCode: 'ST', Name: 'Sao Tome and Principe' },
    { CountryCode: 'SA', Name: 'Saudi Arabia' },
    { CountryCode: 'SN', Name: 'Senegal' },
    { CountryCode: 'RS', Name: 'Serbia' },
    { CountryCode: 'SC', Name: 'Seychelles' },
    { CountryCode: 'SL', Name: 'Sierra Leone' },
    { CountryCode: 'SG', Name: 'Singapore' },
    { CountryCode: 'SX', Name: 'Sint Maarten' },
    { CountryCode: 'SK', Name: 'Slovakia' },
    { CountryCode: 'SI', Name: 'Slovenia' },
    { CountryCode: 'SB', Name: 'Solomon Islands' },
    { CountryCode: 'SO', Name: 'Somalia' },
    { CountryCode: 'ZA', Name: 'South Africa' },
    { CountryCode: 'KR', Name: 'South Korea' },
    { CountryCode: 'SS', Name: 'South Sudan' },
    { CountryCode: 'ES', Name: 'Spain' },
    { CountryCode: 'LK', Name: 'Sri Lanka' },
    { CountryCode: 'SD', Name: 'Sudan' },
    { CountryCode: 'SR', Name: 'Suriname' },
    { CountryCode: 'SJ', Name: 'Svalbard and Jan Mayen' },
    { CountryCode: 'SZ', Name: 'Swaziland' },
    { CountryCode: 'SE', Name: 'Sweden' },
    { CountryCode: 'CH', Name: 'Switzerland' },
    { CountryCode: 'SY', Name: 'Syria' },
    { CountryCode: 'TW', Name: 'Taiwan' },
    { CountryCode: 'TJ', Name: 'Tajikistan' },
    { CountryCode: 'TZ', Name: 'Tanzania' },
    { CountryCode: 'TH', Name: 'Thailand' },
    { CountryCode: 'TG', Name: 'Togo' },
    { CountryCode: 'TK', Name: 'Tokelau' },
    { CountryCode: 'TO', Name: 'Tonga' },
    { CountryCode: 'TT', Name: 'Trinidad and Tobago' },
    { CountryCode: 'TN', Name: 'Tunisia' },
    { CountryCode: 'TR', Name: 'Turkey' },
    { CountryCode: 'TM', Name: 'Turkmenistan' },
    { CountryCode: 'TC', Name: 'Turks and Caicos Islands' },
    { CountryCode: 'TV', Name: 'Tuvalu' },
    { CountryCode: 'VI', Name: 'U.S. Virgin Islands' },
    { CountryCode: 'UG', Name: 'Uganda' },
    { CountryCode: 'UA', Name: 'Ukraine' },
    { CountryCode: 'AE', Name: 'United Arab Emirates' },
    { CountryCode: 'GB', Name: 'United Kingdom' },
    { CountryCode: 'UY', Name: 'Uruguay' },
    { CountryCode: 'UZ', Name: 'Uzbekistan' },
    { CountryCode: 'VU', Name: 'Vanuatu' },
    { CountryCode: 'VA', Name: 'Vatican' },
    { CountryCode: 'VE', Name: 'Venezuela' },
    { CountryCode: 'VN', Name: 'Vietnam' },
    { CountryCode: 'WF', Name: 'Wallis and Futuna' },
    { CountryCode: 'EH', Name: 'Western Sahara' },
    { CountryCode: 'YE', Name: 'Yemen' },
    { CountryCode: 'ZM', Name: 'Zambia' },
    { CountryCode: 'ZW', Name: 'Zimbabwe' }
  ];
}

export async function getStatesList(countryCode) {
  const allStates = [
    { CountryCode: 'CN', StateCode: 'AB', Name: 'Alberta' },
    { CountryCode: 'AU', StateCode: 'ACT', Name: 'Australian Capital Territory' },
    { CountryCode: 'US', StateCode: 'AK', Name: 'Alaska' },
    { CountryCode: 'US', StateCode: 'AL', Name: 'Alabama' },
    { CountryCode: 'US', StateCode: 'AR', Name: 'Arkansas' },
    { CountryCode: 'US', StateCode: 'AS', Name: 'American Samoa' },
    { CountryCode: 'US', StateCode: 'AZ', Name: 'Arizona' },
    { CountryCode: 'CA', StateCode: 'BC', Name: 'British Columbia' },
    { CountryCode: 'US', StateCode: 'CA', Name: 'California' },
    { CountryCode: 'US', StateCode: 'CO', Name: 'Colorado' },
    { CountryCode: 'US', StateCode: 'CT', Name: 'Connecticut' },
    { CountryCode: 'US', StateCode: 'DC', Name: 'District of Columbia' },
    { CountryCode: 'US', StateCode: 'DE', Name: 'Delaware' },
    { CountryCode: 'US', StateCode: 'FL', Name: 'Florida' },
    { CountryCode: 'US', StateCode: 'GA', Name: 'Georgia' },
    { CountryCode: 'US', StateCode: 'GU', Name: 'Guam' },
    { CountryCode: 'US', StateCode: 'HI', Name: 'Hawaii' },
    { CountryCode: 'US', StateCode: 'IA', Name: 'Iowa' },
    { CountryCode: 'US', StateCode: 'ID', Name: 'Idaho' },
    { CountryCode: 'US', StateCode: 'IL', Name: 'Illinois' },
    { CountryCode: 'US', StateCode: 'IN', Name: 'Indiana' },
    { CountryCode: 'US', StateCode: 'KS', Name: 'Kansas' },
    { CountryCode: 'US', StateCode: 'KY', Name: 'Kentucky' },
    { CountryCode: 'US', StateCode: 'LA', Name: 'Louisiana' },
    { CountryCode: 'US', StateCode: 'MA', Name: 'Massachusetts' },
    { CountryCode: 'CA', StateCode: 'MB', Name: 'Manitoba' },
    { CountryCode: 'US', StateCode: 'MD', Name: 'Maryland' },
    { CountryCode: 'US', StateCode: 'ME', Name: 'Maine' },
    { CountryCode: 'US', StateCode: 'MI', Name: 'Michigan' },
    { CountryCode: 'US', StateCode: 'MN', Name: 'Minnesota' },
    { CountryCode: 'US', StateCode: 'MO', Name: 'Missouri' },
    { CountryCode: 'US', StateCode: 'MP', Name: 'Northern Mariana Islands' },
    { CountryCode: 'US', StateCode: 'MS', Name: 'Mississippi' },
    { CountryCode: 'US', StateCode: 'MT', Name: 'Montana' },
    { CountryCode: 'CA', StateCode: 'NB', Name: 'New Brunswick' },
    { CountryCode: 'US', StateCode: 'NC', Name: 'North Carolina' },
    { CountryCode: 'US', StateCode: 'ND', Name: 'North Dakota' },
    { CountryCode: 'US', StateCode: 'NE', Name: 'Nebraska' },
    { CountryCode: 'US', StateCode: 'NH', Name: 'New Hampshire' },
    { CountryCode: 'US', StateCode: 'NJ', Name: 'New Jersey' },
    { CountryCode: 'CA', StateCode: 'NL', Name: 'Newfoundland and Labrador' },
    { CountryCode: 'US', StateCode: 'NM', Name: 'New Mexico' },
    { CountryCode: 'CA', StateCode: 'NS', Name: 'Nova Scotia' },
    { CountryCode: 'AU', StateCode: 'NSW', Name: 'New South Wales' },
    { CountryCode: 'AU', StateCode: 'NT', Name: 'Nothern Territory' },
    { CountryCode: 'CA', StateCode: 'NU', Name: 'Nunavut' },
    { CountryCode: 'US', StateCode: 'NV', Name: 'Nevada' },
    { CountryCode: 'US', StateCode: 'NY', Name: 'New York' },
    { CountryCode: 'US', StateCode: 'OH', Name: 'Ohio' },
    { CountryCode: 'US', StateCode: 'OK', Name: 'Oklahoma' },
    { CountryCode: 'CA', StateCode: 'ON', Name: 'Ontario' },
    { CountryCode: 'US', StateCode: 'OR', Name: 'Oregon' },
    { CountryCode: 'US', StateCode: 'PA', Name: 'Pennsylvania' },
    { CountryCode: 'CA', StateCode: 'PE', Name: 'Prince Edward Island' },
    { CountryCode: 'US', StateCode: 'PR', Name: 'Puerto Rico' },
    { CountryCode: 'CA', StateCode: 'PW', Name: 'Palau' },
    { CountryCode: 'CA', StateCode: 'QC', Name: 'Québec' },
    { CountryCode: 'AU', StateCode: 'QLD', Name: 'Queensland' },
    { CountryCode: 'US', StateCode: 'RI', Name: 'Rhode Island' },
    { CountryCode: 'AU', StateCode: 'SA', Name: 'South Australia' },
    { CountryCode: 'US', StateCode: 'SC', Name: 'South Carolina' },
    { CountryCode: 'US', StateCode: 'SD', Name: 'South Dakota' },
    { CountryCode: 'CA', StateCode: 'SK', Name: 'Saskatchewan' },
    { CountryCode: 'AU', StateCode: 'TAS', Name: 'Tasmania' },
    { CountryCode: 'US', StateCode: 'TN', Name: 'Tennessee' },
    { CountryCode: 'US', StateCode: 'TX', Name: 'Texas' },
    { CountryCode: 'US', StateCode: 'UT', Name: 'Utah' },
    { CountryCode: 'US', StateCode: 'VA', Name: 'Virginia' },
    { CountryCode: 'US', StateCode: 'VI', Name: 'Virgin Islands' },
    { CountryCode: 'AU', StateCode: 'VIC', Name: 'Victoria' },
    { CountryCode: 'US', StateCode: 'VT', Name: 'Vermont' },
    { CountryCode: 'US', StateCode: 'WA', Name: 'Washington' },
    { CountryCode: 'AU', StateCode: 'WAU', Name: 'Western Australia' },
    { CountryCode: 'US', StateCode: 'WI', Name: 'Wisconsin' },
    { CountryCode: 'US', StateCode: 'WV', Name: 'West Virginia' },
    { CountryCode: 'US', StateCode: 'WY', Name: 'Wyoming' },
    { CountryCode: 'CA', StateCode: 'YT', Name: 'Yukon Territory' }
  ];
  const countrySpecificStates = allStates.filter(r => r.CountryCode === countryCode);
  return countrySpecificStates;
}