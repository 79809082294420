export const parseBreckApiErrorForForm = (errorMessages) => {
    const errors = {};

    errorMessages.ModelState.forEach(function (err) {
        const [key, message] = removeBreckFormatting(err);
        // errosr come back in proper case
        // does not account for camel case fields at this time
        errors[key.toLowerCase()] = message.trim();
    });

    return errors;
}

const removeBreckFormatting = (string) => {
    string = string.replace('Validation failed: \r\n -- ', '');
    return string.split(':');
}
