import React, { Children, cloneElement, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import TextButton from '../../common/components/buttons/text-button';
import ChevronUp from '../../common/components/icons/chevron-up';
import ChevronDown from '../../common/components/icons/chevron-down';
import { colors } from '../../app-theme';

const DonorProfileMedicalHistoryTable = ({ children, defaultExpanded, heading }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [familyMember, setFamilyMember] = useState('all');

  const elements = Children.map(children, child => cloneElement(child, { familyMember }));

  return (
    <div className="donor-profile__medical-history-section">
      <h5 onClick={() => setExpanded(!expanded)}>
        {heading}
        {expanded ? (
          <ChevronUp size={20} fill={colors.brandBlue} />
        ) : (
          <ChevronDown size={20} fill={colors.brandBlue} />
        )}
      </h5>
      {!expanded && <hr className="section-divider" />}
      {expanded && (
        <Fragment>
          <div className="table__filters">
            <TextButton
              className={cn({ active: familyMember === 'all' })}
              disabled={familyMember === 'all'}
              onClick={() => setFamilyMember('all')}
            >
              All
            </TextButton>
            <TextButton
              className={cn({ active: familyMember === 'donor' })}
              disabled={familyMember === 'donor'}
              onClick={() => setFamilyMember('donor')}
            >
              Donor
            </TextButton>
            <TextButton
              className={cn({ active: familyMember === 'parents' })}
              disabled={familyMember === 'parents'}
              onClick={() => setFamilyMember('parents')}
            >
              Parents
            </TextButton>
            <TextButton
              className={cn({ active: familyMember === 'grandparents' })}
              disabled={familyMember === 'grandparents'}
              onClick={() => setFamilyMember('grandparent')}
            >
              Grandparents
            </TextButton>
          </div>
          <table className="table__horizontal">
            <thead>
              <tr>
                <th>Medical Condition</th>
                <th>Who</th>
              </tr>
            </thead>
            <tbody>{elements}</tbody>
          </table>
          <hr className="section-divider" />
        </Fragment>
      )}
    </div>
  );
};

DonorProfileMedicalHistoryTable.propTypes = {
  defaultExpanded: PropTypes.bool,
  heading: PropTypes.node.isRequired,
};

DonorProfileMedicalHistoryTable.defaultProps = {
  defaultExpanded: false,
};

export default DonorProfileMedicalHistoryTable;
