import React, { useCallback, useEffect, useRef, useState } from 'react';
import Slider from '@material-ui/core/Slider';
import cn from 'classnames';

import { useEventListener } from '../../common/utils/hooks';
import { colors } from '../../app-theme';
import ChevronRight from '../../common/components/icons/chevron-right';
import Close from '../../common/components/icons/close';

import { useQuery } from '../../common/utils/hooks';

const MAX = 76;
const MIN = 56;

const OPTIONS = [
  { label: '4\' 08" / 1.42 m', value: 56 },
  { label: '4\' 09" / 1.45 m', value: 57 },
  { label: '4\' 10" / 1.47 m', value: 58 },
  { label: '4\' 11" / 1.50 m', value: 59 },
  { label: '5\' 00" / 1.52 m', value: 60 },
  { label: '5\' 01" / 1.55 m', value: 61 },
  { label: '5\' 02" / 1.57 m', value: 62 },
  { label: '5\' 03" / 1.60 m', value: 63 },
  { label: '5\' 04" / 1.63 m', value: 64 },
  { label: '5\' 05" / 1.65 m', value: 65 },
  { label: '5\' 06" / 1.68 m', value: 66 },
  { label: '5\' 07" / 1.70 m', value: 67 },
  { label: '5\' 08" / 1.73 m', value: 68 },
  { label: '5\' 09" / 1.75 m', value: 69 },
  { label: '5\' 10" / 1.78 m', value: 70 },
  { label: '5\' 11" / 1.80 m', value: 71 },
  { label: '6\' 00" / 1.83 m', value: 72 },
  { label: '6\' 01" / 1.85 m', value: 73 },
  { label: '6\' 02" / 1.88 m', value: 74 },
  { label: '6\' 03" / 1.91 m', value: 75 },
  { label: '6\' 04" / 1.93 m', value: 76 },
];

const DonorHeightSlider = ({ label }) => {
  const wrapperRef = useRef(null);
  const [query, setQuery] = useQuery();
  const [max, setMax] = useState(Number.parseInt(query.get('max_height', 76), 10));
  const [min, setMin] = useState(Number.parseInt(query.get('min_height', 56), 10));

  const [expanded, setExpanded] = useState();

  const handler = useCallback(
    e => {
      if (!wrapperRef.current.contains(e.target)) {
        setExpanded(false);
      }
    },
    [setExpanded]
  );

  useEffect(() => {
    setMax(Number.parseInt(query.get('max_height', 76), 10));
    setMin(Number.parseInt(query.get('min_height', 56), 10));
  }, [query]);

  useEventListener('mousedown', handler, document);

  return (
    <div ref={wrapperRef} className="trait-selector trait-slider" onClick={() => setExpanded(!expanded)}>
      <div className="trait-selector__scaffold">
        <div className={cn('trait-selector__value', min !== MIN && max !== MAX && 'trait-selector__value-small')}>
          {min === MIN && max === MAX && 'Any'}
          {min !== MIN && OPTIONS.find(({ value }) => value === min).label}
          {min !== MIN && max !== MAX && ' to '}
          {max !== MAX && OPTIONS.find(({ value }) => value === max).label}
        </div>
        <div id="vertical-slider" className="trait-selector__label">
          {label}{' '}
          {(min !== MIN || max !== MAX) && (
            <span>
              (
              <span
                className="trait-selector__clear-all"
                onClick={e => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();

                  setQuery(
                    query
                      .delete('min_height')
                      .delete('max_height')
                      .delete('from')
                  );
                }}
              >
                Clear All
              </span>
              )
            </span>
          )}
        </div>
      </div>
      <div className="trait-selector__arrow">
        <ChevronRight size={32} fill={colors.brandBlue} />
      </div>
      <div className="trait-selector__options trait-selector__height" style={{ display: expanded ? 'block' : 'none' }}>
        <div className="trait-selector__options-header">
          <div className="options-list--header">
            <span className="trait-selector_header">Select {label}</span>
            {(min !== MIN || max !== MAX) && (
              <span
                className="trait-selector__clear-all"
                onClick={() =>
                  setQuery(
                    query
                      .delete('min_height')
                      .delete('max_height')
                      .delete('from')
                  )
                }
              >
                Clear All
              </span>
            )}
          </div>
          <div className="close-icon">
            <Close size={20} fill="#1E1D1E" />
          </div>
        </div>
        <div
          className="trait-selector__range"
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <Slider
            aria-labelledby="vertical-slider"
            defaultValue={[MAX, MIN]}
            max={MAX}
            min={MIN}
            onChange={(e, [min, max]) => {
              setMin(min);
              setMax(max);
            }}
            onChangeCommitted={(e, [min, max]) => {
              let newQuery = query
                .delete('min_height')
                .delete('max_height')
                .delete('from');

              if (max < MAX) {
                newQuery = newQuery.set('max_height', max);
              }

              if (min > MIN) {
                newQuery = newQuery.set('min_height', min);
              }

              setQuery(newQuery);
            }}
            orientation="vertical"
            value={[min, max]}
            valueLabelDisplay="on"
            valueLabelFormat={val => OPTIONS.find(({ value }) => value === val).label}
          />
        </div>
      </div>
    </div>
  );
};

export default DonorHeightSlider;
