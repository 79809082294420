import React from 'react';
import { useHistory } from 'react-router-dom';
import { pickBy } from 'lodash';

import { useQuery } from '../../common/utils/hooks';
import TwebLogoColored from '../../common/components/logo/logo-colored';
import TextButton from '../../common/components/buttons/text-button';
import Button from '../../common/components/buttons/button';

const DonorNoResults = () => {
  const history = useHistory();
  const [query, setQuery] = useQuery();

  return (
    <div className="donor-no-results">
      <div className="margin-bottom__m">
        <TwebLogoColored />
      </div>
      <h3 className="margin-bottom__xs">No Matches Found</h3>
      <p className="margin-bottom__l">
        Keeping your search criteria to a minimum will allow for a wider selection of donors
      </p>
      <Button buttonType="primary" onClick={() => history.goBack()} className="margin-bottom__s">
        Remove Last Selection
      </Button>
      <TextButton onClick={() => setQuery(pickBy({ mode: query.get('mode'), option: query.get('option') }))}>
        Clear All Filters
      </TextButton>
    </div>
  );
};

export default DonorNoResults;
