import Client, { JwtPlugin } from '@synapsestudios/fetch-client';
import localstorage from 'store2';
import config from './config';

const client = new Client({ url: config.apiUrl, timeout: 360000 });

client.addPlugin(new JwtPlugin());
client.setJwtTokenGetter(() => 'Bearer ' + localstorage.get('idToken'));

client.on('REQUEST_START', request => {
    if(localstorage.get('X-XSRF-TOKEN')){
        request.headers.append('X-XSRF-TOKEN', localstorage.get('X-XSRF-TOKEN'));
    }
  });

export default client;
