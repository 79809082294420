import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Favorite from '../../common/components/icons/favorite';
import FavoriteOutline from '../../common/components/icons/favorite-o';
import { useQuery } from '../../common/utils/hooks';

import { deleteFavorite, postFavorite } from '../../redux/favorite-actions';

const DonorFavorite = ({ donorId, favorited }) => {
    const dispatch = useDispatch();
    const [query, setQuery] = useQuery();
    const [favoriteDisabled, setFavoriteDisabled] = useState(false);
    const loggedIn = useSelector(({ user }) => user.get('loggedIn'));

    useEffect(() => {
        setFavoriteDisabled(false);
    }, [favorited]);

    return (
        <div
            className="donor-favorite"
            onClick={async (e) => {
                e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
                if (loggedIn) {
                    if (!favoriteDisabled) {
                        if (favorited) {
                            await dispatch(deleteFavorite(donorId));
                        } else {
                            await dispatch(postFavorite(donorId));
                        }
                    }
                } else {
                    setQuery(query.set('profileId', donorId));
                }
            }}
            style={{ cursor: favoriteDisabled ? 'wait' : 'pointer' }}
        >
            {!favorited && <FavoriteOutline size={20} fill="#F46081" />}
            {favorited && <Favorite size={20} fill="#F46081" />}
        </div>
    );
};

export default DonorFavorite;
