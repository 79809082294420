import React from 'react';
import PropTypes from 'prop-types';
import Button from '../components/buttons/button';

const ForceReloadPrompt = ({ children, header }) => {
  return (
    <div className="error-layout">
      <div className="error-layout__content">
        <img
          src="https://tweb-dev.s3-us-west-2.amazonaws.com/static/images/tweb-logo-color.png"
          alt="The World Egg Bank"
        />
        <h2 className="margin-top__l margin-bottom__l">{header}</h2>
        {children}
        <Button buttonType="primary" onClick={() => document.location.reload(true)}>
          Update Now
        </Button>
      </div>
    </div>
  );
};

ForceReloadPrompt.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
};

ForceReloadPrompt.defaultProps = {
  header: 'New Updates are Available',
};

export default ForceReloadPrompt;
