import React from 'react';
import Svg, { G, Path, Circle } from 'svgs';

const QuestionCircle = ({ size, fill }) => {
  return (
    <Svg height={size || 24} width={size || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <G>
        <Path
          fill={fill || '#FFFFFF'}
          d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"
        />
        <Path
          fill={fill || '#FFFFFF'}
          d="M12 6a3.5 3.5 0 0 0-3.5 3.5 1 1 0 0 0 2 0A1.5 1.5 0 1 1 12 11a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.16A3.49 3.49 0 0 0 12 6z"
        />
        <Circle fill={fill || '#FFFFFF'} cx="12" cy="17" r="1" />
      </G>
    </Svg>
  );
};

export default QuestionCircle;
