import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icsi from './icons/icsi';
import Ivf from './icons/ivf';
import Iui from './icons/iui';

import { colors } from '../../app-theme';

const OPTION_TYPES = {
    'icsi': {
      icon: Icsi,
      name: 'ICSI',
      description:
          'These vials are ready to be used for ICSI insemination procedures.  They contain approximately 1 million motile sperm cells total.',
      },
      'ivf': {
          icon: Ivf,
          name: 'IVF',
          description:
              'These vials are prepared for IVF insemination. They contain approximately 5 million motile sperm cells total.',
      },
      'iui': {
          icon: Iui,
          name: 'IUI',
          description:
              'These vials are prepared to be used for IUI procedures without further processing.  They contain approximately 10 million motile sperm cells total.',
      },
  };

const OptionTypeIcon = ({ type, showName, size, position, fill = colors.brandBlue }) => {
  const [isHovering, setHovering] = useState(false);

  const Icon = OPTION_TYPES[type].icon;

  return (
    <div className="tooltip" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      {isHovering ? (
        <div className={cn('tooltip__bubble', `position--${position}`)}>
          <div className="tooltip__title">
            <Icon fill={fill} size={size} />
            <span>{OPTION_TYPES[type].name}</span>
          </div>
          <div className="tooltip__content">{OPTION_TYPES[type].description}</div>
        </div>
      ) : null}
      <Icon fill={fill} size={size} />
      {showName ? <span>{OPTION_TYPES[type].name}</span> : null}
    </div>
  );
};

OptionTypeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  showName: PropTypes.bool,
  type: PropTypes.oneOf(['ivf', 'icsi', 'iui']).isRequired,
};
export default OptionTypeIcon;
