import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as Yup from 'yup';

import Input from '../../common/forms/Input';
import Button from '../../common/components/buttons/button';
import Textarea from '../../common/forms/Textarea';
import { sendContactUsMessage } from '../../redux/contact-actions';
import Loading from '../../common/components/Loading';

const SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(64, '64 character max')
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .max(64, '64 character max')
    .required('Required'),
  email: Yup.string()
    .trim()
    .email('Please use a valid email address')
    .max(256, '256 character max')
    .required('Required'),
  subject: Yup.string()
    .trim()
    .max(150, '150 character max'),
  message: Yup.string()
    .trim()
    .max(3000, '3000 character max')
    .required('Required'),
});

const ContactUsForm = ({ closeButtonText, donorId, isVisible, onClose }) => {
  const dispatch = useDispatch();
  const [isShowingSuccessMessage, setShowingSuccessMessage] = useState(false);

  const [firstName, lastName, email] = useSelector(
    ({ user }) => [
      user.getIn(['data', 'profile', 'firstName']),
      user.getIn(['data', 'profile', 'lastName']),
      user.getIn(['data', 'email']),
    ],
    shallowEqual
  );

  useEffect(() => {
    if (!isVisible) {
      setShowingSuccessMessage(false);
    }
  }, [isVisible]);

  return (
    <div>
      {isShowingSuccessMessage ? (
        <div className="center">
          <div style={{ width: '70%' }}>
            <h3 className="margin-bottom__xs">Message Sent!</h3>
            <p className="paragraph__large">
              Your message has been sent and a Client Representative will be in touch with you soon!
            </p>
          </div>
          <div className="anchor-link margin-top__s" onClick={onClose}>
            {closeButtonText}
          </div>
        </div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            firstName: firstName || '',
            lastName: lastName || '',
            email: email || '',
            subject: donorId ? `I'd like to make an inquiry about Donor #${donorId}` : '',
            message: '',
          }}
          validationSchema={SCHEMA}
          onSubmit={(values, { resetForm }) => {
            dispatch(sendContactUsMessage(values)).then(res => {
              if (res.response.ok) {
                resetForm();
                setShowingSuccessMessage(true);
              }
            });
          }}
        >
          {({ isSubmitting }) =>
            isSubmitting ? (
              <div className="center loading-padded">
                <Loading />
              </div>
            ) : (
              <Form>
                <div className="donor-search__form-container">
                  <div className="input-scaffold__group">
                    <Input
                      autoFocus
                      name="firstName"
                      type="text"
                      label="First Name"
                      placeholder="First Name"
                      maxLength={64}
                    />
                    <Input
                      autoFocus
                      name="lastName"
                      type="text"
                      label="Last Name"
                      placeholder="Last Name"
                      maxLength={64}
                    />
                  </div>
                  <div>
                    <Input
                      autoFocus
                      name="email"
                      type="text"
                      label="Email Address"
                      placeholder="Email Address"
                      maxLength={256}
                    />
                  </div>
                  <div>
                    <Input autoFocus name="subject" type="text" label="Subject" placeholder="Subject" maxLength={150} />
                  </div>
                  <div>
                    <Textarea
                      autoFocus
                      name="message"
                      rows="3"
                      label="Message"
                      placeholder="Message"
                      maxLength={3000}
                    />
                  </div>
                  <Button type="submit">Submit</Button>
                </div>
              </Form>
            )
          }
        </Formik>
      )}
    </div>
  );
};

ContactUsForm.defaultProps = {
  closeButtonText: 'Close window',
};

export default ContactUsForm;
