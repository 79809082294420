import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from 'synfrastructure';

import FavoritesList from './FavoritesList';
import DonorPhotosLightboxModal from '../Donors/DonorPhotosLightboxModal';
import DonorProfileContactUs from '../Donors/DonorProfileContactUs';
import DonorProfileModal from '../Donors/DonorProfileModal';

import Close from '../../common/components/icons/close';
import LeftArrow from '../../common/components/icons/left-arrow';
import { useQuery } from '../../common/utils/hooks';
import { setLightboxPhotoId } from '../../redux/ui-actions';

const Favorites = () => {
  const dispatch = useDispatch();
  const [isShowingContactUs, setShowingContactUs] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [query, setQuery] = useQuery();

  useEffect(() => setProfileId(query.get('profileId')), [query]);
  const recipientId = useSelector(({ user }) => user.getIn(['data', 'legacy_user_id']));
  const lightboxPhotoId = useSelector(({ ui }) => ui.get('lightboxPhotoId'));

  return (
    <div className="favorites-list">
      <div className="previous-link margin-bottom__xl">
        <Link className="button--text button--has-icon" to="/">
          <LeftArrow /> Back to Donor Search
        </Link>

        {recipientId && (
          <div className="recipient-id margin-top__xs">
            <strong>Your recipient ID: {recipientId}</strong>
          </div>
        )}
      </div>
      <FavoritesList />
      <Modal
        className="donor-profile-modal"
        closeButton={
          <span role="img" aria-label="Close Modal">
            {!isShowingContactUs && <Close size={40} />}
            {isShowingContactUs && <LeftArrow size={40} fill="#FFFFFF" />}
          </span>
        }
        isVisible={Boolean(profileId)}
        onClose={() => {
          !isShowingContactUs && setQuery(query.delete('profileId'));
          isShowingContactUs && setShowingContactUs(false);
        }}
      >
        {isShowingContactUs && (
          <DonorProfileContactUs
            closeButtonText="Back to donor profile"
            donorId={profileId}
            onClose={() => setShowingContactUs(false)}
          />
        )}
        {!isShowingContactUs && <DonorProfileModal setShowingContactUs={setShowingContactUs} />}
      </Modal>
      <Modal
        className="lightbox"
        closeButton={
          <span role="img" aria-label="Close Modal">
            <Close size={40} />
          </span>
        }
        isVisible={Boolean(profileId && lightboxPhotoId)}
        onClose={() => dispatch(setLightboxPhotoId())}
      >
        <DonorPhotosLightboxModal />
      </Modal>
    </div>
  );
};

export default Favorites;
