import { isNull, isUndefined } from 'lodash';
import { ThunkActionDispatch } from 'redux-thunk';

import client from '../client';
import { createActionTypes } from './utils';

export const DELETE_FAVORITE = 'DELETE_FAVORITE';
export const DELETE_FAVORITE_REQUEST = 'DELETE_FAVORITE_REQUEST';
export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS';
export const DELETE_FAVORITE_FAILURE = 'DELETE_FAVORITE_FAILURE';

export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST';
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_FAILURE = 'GET_FAVORITES_FAILURE';

export const POST_FAVORITE = 'POST_FAVORITE';
export const POST_FAVORITE_REQUEST = 'POST_FAVORITE_REQUEST';
export const POST_FAVORITE_SUCCESS = 'POST_FAVORITE_SUCCESS';
export const POST_FAVORITE_FAILURE = 'POST_FAVORITE_FAILURE';

export const EDIT_FAVORITE = 'EDIT_FAVORITE';
export const EDIT_FAVORITE_REQUEST = 'EDIT_FAVORITE_REQUEST';
export const EDIT_FAVORITE_SUCCESS = 'EDIT_FAVORITE_SUCCESS';
export const EDIT_FAVORITE_RESET = 'EDIT_FAVORITE_RESET';

export const GET_FAVORITE = 'GET_FAVORITE';

export const { GET_FAVORITE_ERROR, GET_FAVORITE_FAILURE, GET_FAVORITE_REQUEST, GET_FAVORITE_SUCCESS } = createActionTypes(GET_FAVORITE);

const VALID_QUERY = {
    FAVORITES: ['from', 'option'],
    SEARCH: [
        'ancestry',
        'available_in',
        'blood_type',
        'blood_type_rh',
        'eye_color',
        'from',
        'hair_color',
        'hair_texture',
        'height',
        'id',
        'min_height',
        'max_height',
        'option',
        'donor_type',
        'race',
        'cmv_status',
    ],
};

export const deleteFavorite = (donorId: number) => (dispatch: ThunkActionDispatch<any>, getState: () => any) => {
    return dispatch({
        donorId,
        promise: client.delete(`favorites/${donorId}`),
        type: DELETE_FAVORITE,
        data: [donorId],
    });
};

export const getFavorites = (query: any) => (dispatch: ThunkActionDispatch<any>, getState: () => any) => {
    const params = query.filter((value: any, key: any) => VALID_QUERY.FAVORITES.includes(key) && !isNull(value) && !isUndefined(value)).toJS();
    return dispatch({
        type: GET_FAVORITES,
        promise: client.get('donors-auth/search', { ...params, favorited: true }),
        from: query.get('from'),
        data: [query],
    });
};

export const postFavorite = (donorId: number) => (dispatch: ThunkActionDispatch<any>, getState: () => any) => {
    return dispatch({
        promise: client.post(`favorites`, { donorId }),
        type: POST_FAVORITE,
        donorId,
        data: [donorId],
    });
};

export const getFavorite = (donorId: number) => (dispatch: ThunkActionDispatch<any>, getState: () => any) => {
    return dispatch({
        donorId,
        promise: client.get(`favorites/${donorId}`),
        type: GET_FAVORITE,
        data: [donorId],
    });
};

export const editFavorite = (donorId: number, Notes: string) => (dispatch: ThunkActionDispatch<any>, getState: () => any) => {
    let state = getState();
    return dispatch({
        donorId,
        promise: client.patch(`favorites/${donorId}`, { ...state.favorites.favorite, Notes }),
        type: EDIT_FAVORITE,
        data: [donorId, Notes],
    });
};

export const editFavoriteReset = () => ({
    type: EDIT_FAVORITE_RESET,
});

// Used to retry requests on token refresh
// not needed for public endpoints
// maps action type to dispatch function
export const FavoriteActionMap = {
    DELETE_FAVORITE: deleteFavorite,
    GET_FAVORITES: getFavorites,
    POST_FAVORITE: postFavorite,
    GET_FAVORITE: getFavorite,
    EDIT_FAVORITE: editFavorite,
};
