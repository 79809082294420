import React, { useMemo } from 'react';

const DonorProfileMedicalHistoryRow = ({ familyMember, issue, issues, label }) => {
  const familyMembers = useMemo(() => {
    let matches = issues
      .filter(medicalIssue => medicalIssue.get('question_text') === issue)
      .filter(medicalIssue => medicalIssue.get('answer') === 'Yes')
      // @TODO What are the "No" family members supposed to represent?
      .filter(medicalIssue => medicalIssue.get('family_member') !== 'No')
      .sortBy(medicalIssue => medicalIssue.get('family_member_id'));

    if (familyMember === 'parents') {
      matches = matches.filter(medicalIssue => ['Mother', 'Father'].includes(medicalIssue.get('family_member')));
    } else if (familyMember === 'grandparents') {
      matches = matches.filter(medicalIssue => medicalIssue.get('family_memmber') === 'Grand Parent');
    } else if (familyMember === 'donor') {
      matches = matches.filter(medicalIssue => medicalIssue.get('family_member') === 'You');
    }

    matches = matches.map(medicalIssue => {
      if (medicalIssue.get('family_member') === 'You') {
        medicalIssue = medicalIssue.set('family_member', 'Donor');
      }

      return medicalIssue;
    });

    return matches;
  }, [familyMember, issue, issues]);

  return (
    <tr>
      <td>{label || issue}</td>
      <td>
        {familyMembers
          .map(medicalIssue => medicalIssue.get('family_member'))
          .toJS()
          .join(', ')}
        {!familyMembers.size && 'None'}
      </td>
    </tr>
  );
};

export default DonorProfileMedicalHistoryRow;
