import React from 'react';
import Svg, { Path } from 'svgs';

const Plus = ({ fill, size }) => {
  return (
    <Svg height={size || 24} width={size || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <Path
        fill={fill || '#FFFFFF'}
        d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"
      />
    </Svg>
  );
};

export default Plus;
