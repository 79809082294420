import React, { Component } from 'react';
import Button from './buttons/button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="error-layout">
          <div className="error-layout__content">
            <img
              src="https://tweb-dev.s3-us-west-2.amazonaws.com/static/images/tweb-logo-color.png"
              alt="The World Egg Bank"
            />
            <h2 className="margin-top__l margin-bottom__l">Something Went Wrong</h2>
            <Button buttonType="primary" onClick={() => document.location.reload()}>
              Reload Page
            </Button>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
