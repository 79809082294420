import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../components/buttons/button';

const NotFound = () => {
  const history = useHistory();

  return (
    <div className="error-layout__notfound">
      <h2 className="margin-top__l margin-bottom__l">404 - Not Found</h2>
      <Button buttonType="primary" onClick={() => history.goBack()}>
        Go Back
      </Button>
    </div>
  );
};

export default NotFound;
