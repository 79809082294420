import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import ChevronLeft from '../../common/components/icons/chevron-left';
import ChevronRight from '../../common/components/icons/chevron-right';
import LeftArrow from '../../common/components/icons/left-arrow';
import { useEventListener, usePhotos } from '../../common/utils/hooks';
import { setLightboxPhotoId } from '../../redux/ui-actions';
import Button from '../../common/components/buttons/button';

const DonorPhotosLightboxModal = () => {
  const dispatch = useDispatch();
  const [donor, photoId] = useSelector(
    ({ donors: { profile }, ui }) => [profile.get('data'), ui.get('lightboxPhotoId')],
    shallowEqual
  );
  const { photos } = usePhotos(donor);

  const entry = photos.findEntry(photo => photo.get('id') === photoId);

  let nextId;
  let prevId;
  let index;
  let current;

  if (entry) {
    [index, current] = entry;
    nextId = photos.get(index + 1 === photos.size ? 0 : index + 1).get('id');
    prevId = photos.get(index - 1).get('id');
  }

  const handler = useCallback(
    e => {
      if (e.key === 'ArrowLeft' && prevId) {
        dispatch(setLightboxPhotoId(prevId));
      } else if (e.key === 'ArrowRight' && nextId) {
        dispatch(setLightboxPhotoId(nextId));
      }
    },
    [dispatch, nextId, prevId]
  );
  useEventListener('keydown', handler, document);

  if (!entry) {
    return null;
  }

  return (
    <div className="donor-profile-lightbox">
      <div className="lightbox__gallery">
        <div className="button--icon" onClick={() => dispatch(setLightboxPhotoId(prevId))}>
          <ChevronLeft size={48} />
        </div>
        <div className="lightbox__featured-image">
          <img src={current.get('full')} alt={`Donor ${donor.get('donor_id')}`} />
        </div>
        <div className="button--icon" onClick={() => dispatch(setLightboxPhotoId(nextId))}>
          <ChevronRight size={48} />
        </div>
        <div className="lightbox__back-button">
          <Button onClick={() => dispatch(setLightboxPhotoId())} buttonType="white" hasIcon>
            <LeftArrow />
            Back to Donor Profile
          </Button>
        </div>
      </div>
      <div className="lightbox__thumbnails">
        {photos
          .map(photo => (
            <img
              key={photo.get('id')}
              src={photo.get('thumb')}
              onError={({target}) => {
                target.onerror = null;
                target.src = photo.get('thumb').replace(/\.[^.]*$/, '.jpg');
              }}
              alt={`Donor ${donor.get('donor_id')}`}
              onClick={() => dispatch(setLightboxPhotoId(photo.get('id')))}
              className={cn({ current: photo.get('id') === current.get('id') })}
            />
          ))
          .toJS()}
      </div>
    </div>
  );
};

export default DonorPhotosLightboxModal;
