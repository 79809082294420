import React, { Children, cloneElement } from 'react';
import CloseIcon from '../../common/components/icons/close';

const DonorTraitSelectorOptionsList = ({ children, expanded, labelFunc, onClear, plural, trait, values, close }) => {
  const elements = Children.map(children, child => cloneElement(child, { trait, values }));

  return (
    <div className="trait-selector__options" style={{ display: expanded ? 'block' : 'none' }}>
      <div className="trait-selector__options-header">
        <div className="options-list--header">
          <span className="trait-selector_header">Select {labelFunc(values.size, plural)}</span>
          {values.size > 0 && (
            <span className="trait-selector__clear-all" onClick={onClear}>
              Clear All
            </span>
          )}
        </div>
        <div className="close-icon" onClick={close}>
          <CloseIcon size={20} fill="#1E1D1E" />
        </div>
      </div>
      {elements}
    </div>
  );
};

export default DonorTraitSelectorOptionsList;
