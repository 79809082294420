import { refreshToken, userLogout } from "../../redux/user-actions";

import { DonorActionMap } from "../../redux/donor-actions";
import { FavoriteActionMap } from "../../redux/favorite-actions";

// // Used to retry requests on token refresh, not needed for public endpoints
const ActionMap = Object.assign({}, DonorActionMap, FavoriteActionMap);

const makeAction = (action, status, data) => {
    const { promise, ...rest } = action;
    const newAction = {
        ...rest,
        ...{ type: `${action.type}_${status}` },
        ...data,
        originalType: action.type,
    };

    return newAction;
};

var isRefreshing = false;
var refreshQueue = [];

export default (store) => (next) => async (action) => {
    // bypass middleware if action doesn't have a promise, thunk will handle it
    if (!action.promise) {
        return next(action);
    }
    next(makeAction(action, 'REQUEST'));
    try {
        const response = await action.promise;
        if (response.ok) {
            try {
                const resp = response.clone();
                const json = await resp.json();
                return next(makeAction(action, 'SUCCESS', { response, json }));
            } catch {
                return next(makeAction(action, 'SUCCESS', { response }));
            }
        } else if (response.status === 401) {
            refreshQueue.push(action);
            //refresh token
            if (!isRefreshing) {
                isRefreshing = true;
                const success = await refreshToken();
                if (success) {
                    refreshQueue.forEach(action => {
                        if (ActionMap[action.type]) {
                            store.dispatch(ActionMap[action.type](...action.data));
                        }
                    });
                    refreshQueue = [];
                }
                else {
                    return next(makeAction(userLogout()));
                }
                isRefreshing = false;
            }
        } else {
            try {
                const json = await response.json();
                return next(makeAction(action, 'FAILURE', { response, json }));
            } catch {
                return next(makeAction(action, 'FAILURE', { response }));
            }
        }
    } catch (e) {
        next(makeAction(action, 'ERROR', { error: e }));
        console.error(e);
    }
};
