import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Label } from 'synfrastructure';
import { spacing, colors, typography } from '../../app-theme';

const InputScaffold = ({ children, className, disabled, error, id, label, touched }) => {
  const scaffoldClasses = {
    'input-scaffold': true,
    [`${className}`]: className,
  };

  const labelClasses = {
    input: true,
    'input--disabled': disabled,
  };

  return (
    <div className={cn(scaffoldClasses)} style={styles.formField}>
      <Label className={cn(labelClasses)} htmlFor={id}>
        {label && <span className="input-label">{label}</span>}
        {children}
      </Label>
      {touched && error && <div style={styles.validation}>{error}</div>}
    </div>
  );
};

InputScaffold.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  touched: PropTypes.bool,
};

InputScaffold.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  error: null,
  label: null,
  touched: false,
};

const styles = {
  formField: {
    marginBottom: spacing.u2,
  },
  validation: {
    ...typography.small,
    color: colors.danger,
    fontWeight: typography.semi,
    marginTop: spacing.u0,
  },
};

export default InputScaffold;
