import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import store from 'store2';

import DonorCard from './DonorCard';
import DonorListItem from './DonorListItem';
import DonorLoadMoreButton from './DonorLoadMoreButton';
import DonorNav from './DonorNav';
import DonorNoResults from './DonorNoResults';
import DonorSearchButton from './DonorSearchButton';
import DonorListContactUsButton from './DonorListContactUsButton';
import Loading from '../../common/components/Loading';
import { useQuery } from '../../common/utils/hooks';
import { searchDonors, searchDonorsAuth } from '../../redux/donor-actions';

const PAGE_SIZE = 18;

const DonorList = () => {
  const storedSearch = store.get('loginSearch');
  const dispatch = useDispatch();
  const [query] = useQuery(storedSearch || undefined); // set to undefined if falsy to match other instances of empty searches
  const { mode } = query.toJS();
  const profileId = useRef(query.get('profileId'));
  const loggedIn = useSelector(({ user }) => user.get('loggedIn'));

  useEffect(() => {
    profileId.current = query.get('profileId');
  }, [profileId, query]);

  useEffect(() => {
    if (profileId.current === query.get('profileId')) {
        if (loggedIn) {
            dispatch(searchDonorsAuth(query, PAGE_SIZE))
        } else {
            dispatch(searchDonors(query, PAGE_SIZE));
        }
    }
    // eslint-disable-next-line
  }, [query]);

  const [donors, firstPage, lastPage, loaded, total] = useSelector(
    ({ donors: { list } }) => [
      list.get('data'),
      list.get('firstPage'),
      list.get('lastPage'),
      list.get('loaded'),
      list.get('total'),
    ],
    shallowEqual
  );

  let DonorComponent = DonorCard;

  if (mode === 'list') {
    DonorComponent = DonorListItem;
  }

  return (
    <div className="donor-search">
      <div className="donor-search__row margin-bottom__m">
        <DonorListContactUsButton />
      </div>
      <div className="donor-search--header">
        <h1 className="brandBlue">Sperm Donor Search</h1>
        <DonorSearchButton />
      </div>
      <DonorNav />
      {firstPage > 0 && <DonorLoadMoreButton buttonText="Load Previous Results" from={firstPage - PAGE_SIZE} />}
      {loaded && (
        <div
          className={cn('donor-search__donor-list', {
            'donor-search__donor-list--grid': !mode,
            'donor-search__donor-list--list': mode === 'list',
          })}
        >
          {donors.map(donor => (
            <DonorComponent donor={donor} key={donor.get('donor_id')} />
          ))}
        </div>
      )}
      {loaded && !total && <DonorNoResults />}
      {!loaded && (
        <div className="loading-padded">
          <Loading />
        </div>
      )}
      {total > donors.size && <DonorLoadMoreButton from={lastPage + PAGE_SIZE} />}
    </div>
  );
};

export default DonorList;
