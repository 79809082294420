import React from 'react';
import { Link } from 'react-router-dom';

const Deleted = () => (
  <div>
    <h2>This account has been deactivated. Contact support to reactivate this account.</h2>

    <Link className="button button--primary margin-top__m" to="/logout">
      Logout
    </Link>
  </div>
);

export default Deleted;
