import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import { shouldUpdateReducer } from '@synapsestudios/fetch-client-goalie';

import donorsReducer from './donor-reducer';
import uiReducer from './ui-reducer';
import userReducer from './user-reducer';
import styleGuide from '../style-guide/reducers';
import { favoriteReducer } from './favorite-reducer';
import recipentReducer from './recipient-reducer';

export default combineReducers({
    browser: createResponsiveStateReducer({
        extraSmall: 480,
        small: 268,
        medium: 1024,
        large: 1280,
    }),
    donors: donorsReducer,
    locale: (state = [navigator.language || 'en-US', { maximumFractionDigits: 1, minimumFractionDigits: 1 }]) => state,
    shouldUpdate: shouldUpdateReducer,
    styleGuide,
    ui: uiReducer,
    user: userReducer,
    favorites: favoriteReducer,
    recipient: recipentReducer,
});
