import { GET_RECIPIENT_SUCCESS } from './recipient-actions';

export default (state = { Id: null, CountryCode: ''}, action) => {
  console.log(action.type);
  if (action.type === GET_RECIPIENT_SUCCESS) {
    var newState = { Id: action.json.Id, CountryCode: action.json.Address.CountryCode};
    return newState;
  }

  return state ? state: {};
};