import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'synfrastructure';

import DonorList from './DonorList';
import DonorTraits from './DonorTraits';
import DonorProfileContactUs from './DonorProfileContactUs';
import DonorPhotosLightboxModal from './DonorPhotosLightboxModal';
import DonorProfileModal from './DonorProfileModal';
import LoginModal from './LoginModal';

import { setLightboxPhotoId } from '../../redux/ui-actions';
import Close from '../../common/components/icons/close';
import LeftArrow from '../../common/components/icons/left-arrow';
import { useQuery } from '../../common/utils/hooks';

const Donors = () => {
  const [isShowingContactUs, setShowingContactUs] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [query, setQuery] = useQuery();
  const dispatch = useDispatch();
  const lightboxPhotoId = useSelector(({ ui }) => ui.get('lightboxPhotoId'));
  const loggedIn = useSelector(({ user }) => user.get('loggedIn'));

  useEffect(() => setProfileId(query.get('profileId')), [query]);
  useEffect(() => setShowingContactUs(false), [profileId]);

  return (
    <div className="donors-list">
      <DonorTraits />
      <DonorList />
      <Modal
        className="donor-profile-modal"
        closeButton={
          <span role="img" aria-label="Close Modal">
            {!isShowingContactUs && <Close size={40} />}
            {isShowingContactUs && <LeftArrow size={40} fill="#FFFFFF" />}
          </span>
        }
        isVisible={Boolean(loggedIn && profileId)}
        onClose={() => {
          !isShowingContactUs && setQuery(query.delete('profileId'));
          isShowingContactUs && setShowingContactUs(false);
        }}
      >
        {isShowingContactUs && (
          <DonorProfileContactUs
            closeButtonText="Back to donor profile"
            donorId={profileId}
            onClose={() => setShowingContactUs(false)}
          />
        )}
        {!isShowingContactUs && <DonorProfileModal setShowingContactUs={setShowingContactUs} />}
      </Modal>
      <Modal
        className="lightbox"
        closeButton={
          <span role="img" aria-label="Close Modal">
            <Close size={40} />
          </span>
        }
        isVisible={Boolean(profileId && lightboxPhotoId)}
        onClose={() => dispatch(setLightboxPhotoId())}
      >
        <DonorPhotosLightboxModal />
      </Modal>
      <Modal
        className="login-modal"
        closeButton={
          <span role="img" aria-label="Close Modal">
            <Close size={40} />
          </span>
        }
        isVisible={Boolean(!loggedIn && profileId)}
        onClose={() => setQuery(query.delete('profileId'))}
      >
        {Boolean(!loggedIn && profileId) && <LoginModal />}
      </Modal>
    </div>
  );
};

export default Donors;
