import { fromJS } from 'immutable';
import { AnyAction } from 'redux';
import store from 'store2';
import config from '../config';
import { DELETE_FAVORITE_SUCCESS, POST_FAVORITE_SUCCESS } from './favorite-actions';
import { GET_USER_SUCCESS, LOGIN_USER_SUCCESS, LOGOUT_USER, VERIFY_EMAIL_SUCCESS } from './user-actions';

const loggedIn = !!store.get(config.auth.idTokenKey);
const data = fromJS({ data: { favorites: 0 }, loggedIn, loaded: false });

export default (state = data, action: AnyAction) => {
    if (action.type === GET_USER_SUCCESS) {
        return state.merge(fromJS({ data: action.json, loaded: true }));
    } else if (action.type === LOGIN_USER_SUCCESS || action.type === VERIFY_EMAIL_SUCCESS) {
        return state.set('loggedIn', true);
    } else if (action.type === LOGOUT_USER) {
        return state.merge({ loggedIn: false, data: {} });
    } else if (action.type === POST_FAVORITE_SUCCESS) {
        return state.setIn(['data', 'favorites'], state.getIn(['data', 'favorites']) + 1);
    } else if (action.type === DELETE_FAVORITE_SUCCESS) {
        return state.setIn(['data', 'favorites'], state.getIn(['data', 'favorites']) - 1);
    }

    return state;
};
