enum ActionTypeSuffix {
    REQUEST = 'REQUEST',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    ERROR = 'ERROR',
}

// builds strongly typed action types following the same principles as the client middleware
export const createActionTypes = <T extends string>(typeBase: T) => Object
    .values(ActionTypeSuffix)
    .reduce((acc, curr) => ({ ...acc, [`${typeBase}_${curr}`]: `${typeBase}_${curr}` }), {}) as
    {[key in `${T}_${ActionTypeSuffix}`]: string};
