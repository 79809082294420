export const SET_LIGHTBOX_PHOTO_ID = 'SET_LIGHTBOX_PHOTO_ID';
export const SET_SHOW_OPTION_TYPES_MODAL = 'SET_SHOW_OPTION_TYPES_MODAL';

export const setLightboxPhotoId = (lightboxPhotoId = null) => ({
  type: SET_LIGHTBOX_PHOTO_ID,
  lightboxPhotoId,
});

export const setShowOptionTypesModal = (visible = false) => ({
  type: SET_SHOW_OPTION_TYPES_MODAL,
  visible,
});
