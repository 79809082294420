import React, { Children, cloneElement, useRef, useState, useMemo } from 'react';
import Fuse from 'fuse.js';
import Close from '../../common/components/icons/close';
import { colors } from '../../app-theme';

const DonorTraitSelectorSearchableList = ({ onClear, values, trait, expanded, children, close }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const fuse = useRef(
    new Fuse(children, {
      threshold: 0.3,
      distance: 2,
      keys: ['props.label'],
    })
  );

  const childrenToDisplay = useMemo(() => (searchTerm.trim() ? fuse.current.search(searchTerm) : children), [
    searchTerm,
    children,
  ]);

  const elements = Children.map(childrenToDisplay, child => cloneElement(child, { trait, values }));

  return (
    <div className="trait-selector__options" style={{ display: expanded ? 'block' : 'none' }}>
      <div className="trait-selector__options-header">
        <div className="trait-selector__search-wrapper">
          <div className="trait-selector-input--clear__wrapper">
            <input
              type="text"
              className="trait-selector__search"
              placeholder={`Type here to search`}
              onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.currentTarget.value);
              }}
            />
            {values.size > 0 && (
              <span className="trait-selector__clear-all" onClick={onClear}>
                Clear All
              </span>
            )}
          </div>
          <div className="trait-selector__close" onClick={close}>
            <Close fill={colors.darkGray} />
          </div>
        </div>
      </div>
      {elements}
    </div>
  );
};

export default DonorTraitSelectorSearchableList;
