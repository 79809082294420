import React from 'react';
import { Route } from 'react-router-dom';

const LayoutRoute = ({ component: Component, layout: Layout, ...props }) => (
  <Route
    {...props}
    render={routeProps => (
      <Layout>
        {Component && <Component {...routeProps} />}
        {!Component && props.render(routeProps)}
      </Layout>
    )}
  />
);

export default LayoutRoute;
