import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { newQuery } from '../../common/utils/query-helpers';
import { useQuery } from '../../common/utils/hooks';
import GridView from '../../common/components/icons/grid-view';
import ListView from '../../common/components/icons/list-view';
import { colors } from '../../app-theme';

const DonorNav = () => {
  const location = useLocation();
  const [query] = useQuery();
  const { mode, option } = query.toJS();

  return (
    <nav className="tab-filters margin-bottom__xl">
      <ul>
        <li>
          <NavLink
            exact
            isActive={() => !option}
            to={{ ...location, search: `?${newQuery(query.delete('option').delete('from'))}` }}
          >
            All Donors
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            isActive={() => option === 'iui'}
            to={{ ...location, search: `?${newQuery(query.set('option', 'iui').delete('from'))}` }}
          >
            IUI
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            isActive={() => option === 'ivf'}
            to={{ ...location, search: `?${newQuery(query.set('option', 'ivf').delete('from'))}` }}
          >
            IVF
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            isActive={() => option === 'icsi'}
            to={{ ...location, search: `?${newQuery(query.set('option', 'icsi').delete('from'))}` }}
          >
            ICSI
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            isActive={() => option === 'upcoming'}
            to={{ ...location, search: `?${newQuery(query.set('option', 'upcoming').delete('from'))}` }}
          >
            Coming Soon
          </NavLink>
        </li>
        <li className="view-toggles">
          <Link to={{ ...location, search: `?${newQuery(query.delete('mode'))}` }}>
            <GridView size={32} fill={mode ? colors.lightGray : colors.brandBlue} />
          </Link>
          <Link to={{ ...location, search: `?${newQuery(query.set('mode', 'list'))}` }}>
            <ListView size={32} fill={mode === 'list' ? colors.brandBlue : colors.lightGray} />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default DonorNav;
