import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import RegisterForm from './RegisterForm';
import { typography, spacing } from '../../app-theme';
import "@fontsource-variable/instrument-sans";
import "@fontsource-variable/manrope";

const Register = () => {
    const [registrationSuccessfull, setRegistrationSuccessfull] = useState(false);

    return (
        <div>
            <div className="auth-layout__form-container">
                <h2 style={styles.h2}>One step away from finding your perfect donor.</h2>
                <h3 style={styles.h3}>
                    Already have an account? <Link to="/login">Sign in</Link>
                </h3>
                {!registrationSuccessfull && <RegisterForm onSuccess={() => setRegistrationSuccessfull(true)} />}
                {registrationSuccessfull && (
                    <div>
                        <h4 style={styles.h4}>Almost there!</h4>
                        <p>Verify your account by clicking the link in the email we just sent you.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const styles = {
    h2: {
        ...typography.displayL,
        marginBottom: spacing.u4,
    },
    h3: {
        ...typography.displayS,
        marginBottom: spacing.u4,
        fontWeight: typography.regular,
    },
    h4: {
        ...typography.displayS,
        marginBottom: spacing.u1,
    },
};

export default Register;
