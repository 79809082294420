import { Component } from 'react';
import { connect } from 'react-redux';
import { userLogout } from '../../redux/user-actions';

class Logout extends Component {
    componentDidMount() {
        this.props.userLogout();
        this.props.history.push('');
    }

    render() {
        return null;
    }
}

export default connect(null, { userLogout })(Logout);
