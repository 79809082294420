import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import StyleGuidePage from './style-guide/StyleGuidePage';
import Logout from './common/auth/Logout';
import ErrorBoundary from './common/components/ErrorBoundary';
import ForceReloadPrompt from './common/components/ForceReloadPrompt';
import NotFound from './common/components/NotFound';
import LayoutRoute from './common/components/LayoutRoute';

import { verifyUserEmail, getUser } from './redux/user-actions';

// Layouts
import Auth from './layouts/Auth';
import Main from './layouts/Main';

// Views
import Contact from './views/Contact';
import Deleted from './views/Deleted';
import Donors from './views/Donors';
import Favorites from './views/Favorites';
import Register from './views/Register';
import Login from './views/Login/Login';
import { ForgotPassword } from './views/ForgotPassword/ForgotPassword';
import { ResetPassword } from './views/ForgotPassword/ResetPassword';

class App extends Component {
    componentDidMount() {
        const { history, location, verifyUserEmail, loaded, loggedIn } = this.props;
        if (location.hash) {
            const { validationKey, userId } = location.hash
                .substring(location.hash.indexOf('?') + 1)
                .split('&')
                .map((str) => str.split('='))
                .reduce((prev, curr) => {
                    prev[curr[0]] = curr[1];
                    return prev;
                }, {});

            if (validationKey && userId) {
                const payload = {
                    ValidationKey: validationKey,
                    UserId: parseInt(userId, 10),
                };
                verifyUserEmail(payload);
            }

            history.replace('');
        }

        if (loggedIn && !loaded) {
            this.props.getUser();
        }
    }

    render() {
        const { shouldUpdate, loggedIn, deleted } = this.props;

        if (shouldUpdate) {
            return (
                <ErrorBoundary>
                    <ForceReloadPrompt />
                </ErrorBoundary>
            );
        }

        if (loggedIn && deleted) {
            return (
                <ErrorBoundary>
                    <Switch>
                        <LayoutRoute exact path="/contact" component={Contact} layout={Main} />
                        <Route exact path="/logout" component={Logout} layout={Auth} />
                        <LayoutRoute path="*" component={Deleted} layout={Auth} />
                    </Switch>
                </ErrorBoundary>
            );
        }

        return (
            <ErrorBoundary>
                <Switch>
                    {loggedIn && <LayoutRoute exact path="/favorites" component={Favorites} layout={Main} />}
                    <LayoutRoute exact path="/contact" component={Contact} layout={Main} />
                    {!loggedIn && <LayoutRoute exact path="/login" component={Login} layout={Auth} />}
                    {loggedIn && <Redirect exact path="/login" to="/" />}
                    {loggedIn && <Route exact path="/logout" component={Logout} layout={Main} />}
                    {!loggedIn && <LayoutRoute exact path="/register" component={Register} layout={Auth} />}
                    <LayoutRoute path="/forgot-password" component={ForgotPassword} layout={Auth} />
                    <LayoutRoute path="/reset-password" component={ResetPassword} layout={Auth} />
                    <LayoutRoute exact path="/styleguide" component={StyleGuidePage} layout={Main} />
                    <LayoutRoute exact path="/" component={Donors} layout={Main} />
                    <LayoutRoute path="*" component={NotFound} layout={Main} />
                </Switch>
            </ErrorBoundary>
        );
    }
}

export default connect(
    ({ shouldUpdate, user }) => ({
        shouldUpdate,
        deleted: user?.getIn(['data', 'deleted']),
        loaded: user?.get('loaded'),
        loggedIn: user?.get('loggedIn'),
        user: user?.get('data'),
    }),
    { verifyUserEmail, getUser },
)(withRouter(App));
