import React from 'react';
import Svg, { G, Path, Circle } from 'svgs';

const InfoCircle = ({ size, fill }) => {
  return (
    <Svg height={size || 24} width={size || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <G>
        <Path
          fill={fill || '#FFFFFF'}
          d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"
        />
        <Circle cx="12" cy="8" r="1" />
        <Path fill={fill || '#FFFFFF'} d="M12 10a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1z" />
      </G>
    </Svg>
  );
};

export default InfoCircle;
