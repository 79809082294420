import React from 'react';
import Svg, { G, Path, Polyline, Circle, Line } from 'svgs';
import { colors } from '../../../app-theme';

const Icsi = ({ size, fill }) => {
  return (
    <Svg height={size || 28} width={size || 28} viewBox="0 0 120.97 101.41" xmlns="http://www.w3.org/2000/svg" fill="none" stroke={fill || colors.brandBlue} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">

                    <Path d="M28.6,45.34c2.91-13.47,14.9-23.56,29.24-23.56,16.52,0,29.92,13.4,29.92,29.92s-13.4,29.92-29.92,29.92c-14.01,0-25.77-9.62-29.02-22.62"/>
                    <Path d="M41.11,40.8c3.73-12.48,21-12.05,29.6-5.43,4.68,3.6,8,9.19,8.4,15.14,.88,13.21-11.85,23.53-24.54,22.61-6.98-.51-12.15-5.34-13.73-12.01"/>
                    <Polyline points="1.5 50.88 54.73 50.88 46.08 54.76"/>
                        <G>
                            <Path d="M32.31,2.62c2.96,1.81,3.89,5.67,2.08,8.62-1.81,2.96-5.67,3.89-8.62,2.08-2.96-1.81-3.89-5.67-2.08-8.62,1.81-2.96,5.67-3.89,8.62-2.08Z"/>
                            <Path d="M34.29,2.99c1.57-1.62,4.08-1.99,6.07-.77,2.29,1.4,3.02,4.42,1.61,6.73-1.15,1.87-3.38,2.7-5.38,2.17"/>
                        </G>
                        <G>
                            <G>
                                <Path d="M94.64,97.38c-3.29-2.21-4.16-6.66-1.95-9.95,2.21-3.29,6.66-4.16,9.95-1.95,3.29,2.21,4.16,6.66,1.95,9.95-2.21,3.29-6.66,4.16-9.95,1.95Z"/>
                                <Path d="M91.55,96.06c-1.44,1.37-3.67,1.6-5.38,.45-1.97-1.32-2.5-4.01-1.16-6,1.08-1.61,3.09-2.26,4.83-1.71"/>
                            </G>
                            <Path d="M104.72,86.09c1.25-.31,2.36-1.09,3.07-2.29,1.36-2.32,.59-5.29-1.73-6.65-2.32-1.36-5.31-.59-6.67,1.73-.85,1.44-.87,3.13-.22,4.56"/>
                        </G>
                        <Circle cx="97.91" cy="16.33" r="5.28"/>
                        <Circle cx="32.72" cy="94.63" r="5.28"/>
                        <Path d="M21.04,70.4c0,1.68-1.36,3.04-3.04,3.04s-3.04-1.36-3.04-3.04,1.36-3.04,3.04-3.04,3.04,1.36,3.04,3.04Z"/>
                        <Path d="M85.84,10.54c0,1.68-1.36,3.04-3.04,3.04s-3.04-1.36-3.04-3.04,1.36-3.04,3.04-3.04,3.04,1.36,3.04,3.04Z"/>
                        <G>
                            <Line x1="96.39" y1="51.48" x2="105.03" y2="51.48"/>
                            <Path d="M116.4,56.44l-7.43,.14c-1.5,0-2.22-.1-3.08-.99h0c-.55-.57-.85-1.24-.85-1.94v-3.79c0-.74,.42-1.45,1.17-1.95h0c.68-.46,1.57-.71,2.5-.71h7.69"/>
                            <Path d="M119.47,41.41h-17.7c-1.49,0-2.93,.43-4.1,1.22h0c-1.58,1.07-2.49,2.69-2.49,4.41v9.7c0,1.72,.92,3.34,2.49,4.41h0c1.17,.79,2.61,1.22,4.1,1.22h17.7"/>
                        </G>
    </Svg>
  );
};

export default Icsi;
