import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Input from '../../common/forms/Input';

import { getToken } from '../../redux/user-actions';
import StyledButton from '../../common/components/buttons/button';

let StyledInput: any = Input;

const SCHEMA = Yup.object().shape({
    email: Yup.string()
        .trim()
        .email()
        .required('Please enter your email'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Please enter a password'),
});

export default () => {
    const history = useHistory();

    const [showPassword] = useState(false);
    const dispatch = useDispatch();

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            onSubmit={async (values, { setErrors }) => {
                const payload = {
                    Password: values.password,
                    Username: values.email,
                };
                const { response } = (await dispatch(getToken(payload))) as any;
                if (response.ok) {
                    history.push('');
                } else if (response.status === 418) {
                    setErrors({password: 'Email/Password is not correct'});
                }
            }}
            validationSchema={SCHEMA}
        >
            {({ isSubmitting }) => (
                <Form>
                    <StyledInput
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        placeholder="jane.doe@example.com"
                        disabled={isSubmitting}
                        rounded
                    />
                    <StyledInput
                        disabled={isSubmitting}
                        id="password"
                        name="password"
                        label="Password"
                        placeholder="Password"
                        type={showPassword ? 'text' : 'password'}
                        rounded
                    ></StyledInput>
                    <p className="margin-bottom__l">
                        Don't have an account? <Link to="/register">Create an account</Link>
                    </p>

                    <StyledButton type="submit" buttonType="primary" disabled={isSubmitting}>
                        Log In
                    </StyledButton>
                    <p className="margin-top__s">
                        <Link to="/forgot-password">Forgot password?</Link>
                    </p>
                </Form>
            )}
        </Formik>
    );
};
