import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { editFavorite, editFavoriteReset, getFavorite } from '../../redux/favorite-actions';
import TextButton from '../../common/components/buttons/text-button';

const DonorProfileNotes = ({ donorId }) => {
    const favorite = useSelector((state) => {
        if (donorId === state?.favorites?.favorite?.DonorId) {
            return state?.favorites?.favorite;
        }
    }, shallowEqual);

    const [notes, setNotes] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFavorite(donorId));

        return () => dispatch(editFavoriteReset());
    }, [dispatch, donorId]);

    useEffect(() => {
        if (favorite?.Notes) {
            setNotes(favorite?.Notes);
        }
    }, [favorite]);

    const [saving, saved] = useSelector(
        ({ donors: { editFavoriteApiRequest } }) => [editFavoriteApiRequest.get('saving'), editFavoriteApiRequest.get('saved')],
        shallowEqual,
    );

    let saveButtonText = 'Save';
    if (saving) {
        saveButtonText = 'Saving...';
    } else if (saved) {
        saveButtonText = 'Saved!';
    }

    return (
        (favorite && (
            <div className="donor-profile__notes">
                <h5 className="margin-bottom__xs">Notes</h5>
                <textarea
                    className="donor-profile__textarea"
                    disabled={saving}
                    placeholder="1000 characters or less"
                    value={notes || ''}
                    onChange={(e) => {
                        setNotes(e.currentTarget.value.slice(0, 1000));
                    }}
                />
                <TextButton
                    onClick={() => {
                        dispatch(editFavorite(favorite.DonorId, notes));
                    }}
                    disabled={saving}
                    className="donor-profile__text-button"
                >
                    {saveButtonText}
                </TextButton>
            </div>
        )) ||
        null
    );
};

export default DonorProfileNotes;
