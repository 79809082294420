import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Button extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    destructive: PropTypes.bool,
    className: PropTypes.string,
    buttonType: PropTypes.oneOf(['primary', 'primary-alt', 'white', 'secondary']).isRequired,
    hasIcon: PropTypes.bool,
    iconRight: PropTypes.bool,
  };

  static defaultProps = {
    buttonType: 'primary',
  };

  render() {
    const { children, className, destructive, buttonType, onClick, hasIcon, iconRight, ...passthrough } = this.props;

    return (
      <button onClick={onClick} className={classNames('button', `button--${buttonType}`, className)} {...passthrough}>
        <div className={classNames(hasIcon && 'button--has-icon', iconRight && 'button--icon-right')}>{children}</div>
      </button>
    );
  }
}

export default Button;
