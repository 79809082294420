import React from 'react';
import { useSelector } from 'react-redux';

import Loading from '../../common/components/Loading';
import { useQuery } from '../../common/utils/hooks';

const DonorLoadMoreButton = ({ buttonText, from }) => {
  const loading = useSelector(({ donors: { list } }) => list.get('loading'));
  const [query, setQuery] = useQuery();

  return (
    <div className="donor-search__load-more">
      {loading && <Loading />}
      {!loading && (
        <button className="button" onClick={() => setQuery(query.set('from', from))} type="button">
          {buttonText}
        </button>
      )}
    </div>
  );
};

DonorLoadMoreButton.defaultProps = {
  buttonText: 'Load More Results',
};

export default DonorLoadMoreButton;
