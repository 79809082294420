import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'synfrastructure';

import DonorTraitSelector, { DonorTrait } from './DonorTraitSelector';
import DonorHeightSlider from './DonorHeightSlider';

import Close from '../../common/components/icons/close';
import Plus from '../../common/components/icons/plus';
import Minus from '../../common/components/icons/minus';
import Icsi from '../../common/components/icons/icsi';
import Ivf from '../../common/components/icons/ivf';
import InfoCircle from '../../common/components/icons/info-circle';
import Button from '../../common/components/buttons/button';
import Iui from '../../common/components/icons/iui';

import { useQuery } from '../../common/utils/hooks';

import { colors } from '../../app-theme';

import { setShowOptionTypesModal } from '../../redux/ui-actions';
import { getRecipient } from '../../redux/recipient-actions';

const DonorTraits = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useQuery();
  const [characteristicsExpanded, setCharacteristicsExpanded] = useState(true);
  const [characteristicsShowMore, setCharacteristicsShowMore] = useState(
    query.has('hair_texture') ||
    query.has('min_height') ||
    query.has('max_height') ||
    query.has('blood_type') ||
    query.has('blood_type_rh')
  );
  const [eggAvailabilityExpanded, setEggAvailabilityExpanded] = useState(true);
  const [eggAvailabilityShowMore, setEggAvailabilityShowMore] = useState(
    query.has('donor_type') || query.has('ancestry') || query.has('cmv_status')
  );
  const showOptionTypesModal = useSelector(({ ui }) => ui.get('showOptionTypesModal'));
  const isMobile = useSelector(({ browser }) => browser.lessThan.medium);
  const [editingFilters, setEditingFilters] = useState(false);
  const [lookupFilters, setLookupFilters] = useState([]);

  const [hideCountries, setHideCountries] = useState(true);
  const recipient = useSelector(({ recipient }) => recipient);
  const recipientId = useSelector(({ user }) => user.getIn(['data', 'legacy_user_id']));

  const clearFilters = useCallback(() => {
    const newQuery = query
      .delete('from')
      .delete('race')
      .delete('eye_color')
      .delete('hair_color')
      .delete('hair_texture')
      .delete('min_height')
      .delete('max_height')
      .delete('blood_type')
      .delete('blood_type_rh')
      .delete('donor_type')
      .delete('ancestry')
      .delete('cmv_status');
    if (!recipient) {
      query.delete('available_in')
    }
    setQuery(newQuery);
    setEditingFilters(true);
  }, [query, setQuery, setEditingFilters, recipient]);

  ///
  /// Find the current recipient (if there is one) - and show/hide the country selections accordingly
  ///
  useEffect(() => {
    if (recipientId) {
      ///
      /// We only want to execute this if we don't already have a recipient
      ///
      if (recipient === null || (recipient !== null && recipient.Id !== recipientId)) {
        dispatch(getRecipient(recipientId));
      }

      ///
      /// Hide the country selections if the recipient has a country of residence, but show them otherwise
      ///
      setHideCountries(['US', 'AU', 'CA','UK'].includes(recipient.CountryCode));
    }
    else {
      ///
      /// If there is no recipient Id then this is an anonymous search, so don't hide the country selections
      ///
      setHideCountries(false);
    }
  }, [dispatch, recipientId, recipient]);

  useEffect(() => {
    let currentFilters = query
      .delete('option')
      .delete('max_height')
      .delete('min_height')
      .toList()
      .toJS()
      .reduce((traits, trait) => traits.concat(Array.isArray(trait) ? trait : [trait]), [])
      .filter(Boolean);

    setLookupFilters(currentFilters);
  }, [query, setLookupFilters]);

  return (
    <div className="donor-traits">
      <h4 className="brandBlue">Select Traits</h4>

      {isMobile && lookupFilters.length > 0 && (
        <p>
          <strong>Filtered by:</strong> {lookupFilters.slice(0, 5).join(', ')}
          {lookupFilters.length > 5 && ', etc...'}
        </p>
      )}

      {(!isMobile || editingFilters) && (
        <>
          <p>
            Use the following options to filter donors based on your preferences. Keeping your search criteria to a
            minimum will allow a wider selection of donors
          </p>

          {lookupFilters.length > 0 && (
            <Button
              buttonType="primary-alt"
              className="button--size--medium button--full-width margin-bottom__m"
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
          )}

          <div className="donor-traits__selectors">
            <h6 className="allCaps" onClick={() => setCharacteristicsExpanded(!characteristicsExpanded)}>
              Characteristics
              <span className="toggle">
                {!characteristicsExpanded ? (
                  <Plus size={20} fill={colors.darkGray} />
                ) : (
                  <Minus fill={colors.darkGray} size={20} />
                )}
              </span>
            </h6>
            {characteristicsExpanded && (
              <>
                <DonorTraitSelector label="Race" trait="race" plural>
                  <DonorTrait label="Asian" value="1" />
                  <DonorTrait label="Black" value="2" />
                  <DonorTrait label="Caucasian" value="3" />
                  <DonorTrait label="East Indian" value="5" />
                  <DonorTrait label="Hawaiian" value="6" />
                  <DonorTrait label="Hispanic" value="7" />
                  <DonorTrait label="Middle Eastern" value="4" />
                  <DonorTrait label="Native American" value="8" />
                </DonorTraitSelector>
                <DonorTraitSelector label="Eye Color" trait="eye_color" plural>
                  <DonorTrait label="Black" value="5" />
                  <DonorTrait label="Blue" value="1" />
                  <DonorTrait label="Brown" value="2" />
                  <DonorTrait label="Green" value="4" />
                  <DonorTrait label="Hazel" value="3" />
                </DonorTraitSelector>
                <DonorTraitSelector label="Hair Color" trait="hair_color" plural>
                  <DonorTrait label="Black" value="1" />
                  <DonorTrait label="Blonde" value="2" />
                  <DonorTrait label="Brown" value="3" />
                  <DonorTrait label="Red" value="4" />
                </DonorTraitSelector>
                {characteristicsShowMore && (
                  <DonorTraitSelector label="Hair Texture" trait="hair_texture" plural>
                    <DonorTrait label="Average" value="5" />
                    <DonorTrait label="Curly" value="3" />
                    <DonorTrait label="Straight" value="1" />
                    <DonorTrait label="Thick" value="6" />
                    <DonorTrait label="Thin" value="4" />
                    <DonorTrait label="Wavy" value="2" />
                  </DonorTraitSelector>
                )}
                {characteristicsShowMore && <DonorHeightSlider trait="height" label="Height" />}
                {characteristicsShowMore && (
                  <DonorTraitSelector label="Blood Type" trait="blood_type" plural>
                    <DonorTrait label="A" value="1" />
                    <DonorTrait label="AB" value="2" />
                    <DonorTrait label="B" value="3" />
                    <DonorTrait label="O" value="4" />
                  </DonorTraitSelector>
                )}
                {characteristicsShowMore && (
                  <DonorTraitSelector label="Rh Factor" trait="blood_type_rh" plural>
                    <DonorTrait label="Positive" value="1" />
                    <DonorTrait label="Negative" value="2" />
                  </DonorTraitSelector>
                )}
                {!characteristicsShowMore && (
                  <span className="toggleOptions" onClick={() => setCharacteristicsShowMore(true)}>
                    More Options
                  </span>
                )}
                {characteristicsShowMore && (
                  <span className="toggleOptions" onClick={() => setCharacteristicsShowMore(false)}>
                    Less Options
                  </span>
                )}
              </>
            )}
          </div>

          <div className="donor-traits__selectors">
            <h6 className="allCaps" onClick={() => setEggAvailabilityExpanded(!eggAvailabilityExpanded)}>
              {!hideCountries && <span>Sperm Availability & Type</span>}
              {hideCountries && <span>Sperm Type</span>}
              <span className="toggle">
                {!eggAvailabilityExpanded ? (
                  <Plus size={20} fill={colors.darkGray} />
                ) : (
                  <Minus fill={colors.darkGray} size={20} />
                )}
              </span>
            </h6>
            {eggAvailabilityExpanded && (
              <>
                {!hideCountries && <DonorTraitSelector label="Your Country of Residence" trait="available_in">
                  <DonorTrait label="Available In the US" value="US" />
                  <DonorTrait label="Available In Australia" value="AU" />
                  <DonorTrait label="Available In the UK" value="GB" />
                  <DonorTrait label="Available In Canada" value="CA" />
                  <DonorTrait label="Available In Other Countries" value="XX" />
                </DonorTraitSelector>
                }
                {eggAvailabilityShowMore && (
                  <>
                    <DonorTraitSelector
                      label={size => (
                        <span>
                          Type{' '}
                          {!Boolean(size) && (
                            <span>
                              (
                              <span
                                className="link"
                                onClick={e => {
                                  e.stopPropagation();
                                  e.nativeEvent.stopImmediatePropagation();
                                  dispatch(setShowOptionTypesModal(true));
                                }}
                              >
                                What's this?
                              </span>
                              )
                            </span>
                          )}
                        </span>
                      )}
                      trait="donor_type"
                    >
                      <DonorTrait label="IVF" value="1">
                        <Ivf />
                      </DonorTrait>
                      <DonorTrait label="ICSI" value="2">
                        <Icsi />
                      </DonorTrait>
                      <DonorTrait label="IUI" value="3">
                        <Iui />
                      </DonorTrait>
                    </DonorTraitSelector>
                    <DonorTraitSelector
                      label="Ancestry"
                      trait="ancestry"
                      className="trait-selector__ancestry"
                      isSearchable
                    >
                      <DonorTrait label="African American" value="1" />
                      <DonorTrait label="Alsace" value="2" />
                      <DonorTrait label="Argentinean" value="3" />
                      <DonorTrait label="Armenian" value="4" />
                      <DonorTrait label="Austrian" value="5" />
                      <DonorTrait label="Belgian" value="6" />
                      <DonorTrait label="Bolivian" value="7" />
                      <DonorTrait label="Brazilian" value="8" />
                      <DonorTrait label="Bulgarian" value="9" />
                      <DonorTrait label="Canadian" value="10" />
                      <DonorTrait label="Chilean" value="11" />
                      <DonorTrait label="Chinese" value="12" />
                      <DonorTrait label="Colombian" value="13" />
                      <DonorTrait label="Costa Rican" value="14" />
                      <DonorTrait label="Creole" value="15" />
                      <DonorTrait label="Croatian" value="16" />
                      <DonorTrait label="Czech" value="17" />
                      <DonorTrait label="Danish" value="18" />
                      <DonorTrait label="Dutch" value="19" />
                      <DonorTrait label="East Indian" value="20" />
                      <DonorTrait label="Ecuadorian" value="21" />
                      <DonorTrait label="Egyptian" value="22" />
                      <DonorTrait label="English" value="23" />
                      <DonorTrait label="Ethiopian" value="24" />
                      <DonorTrait label="Filipino" value="25" />
                      <DonorTrait label="Finnish" value="26" />
                      <DonorTrait label="French" value="27" />
                      <DonorTrait label="French-Canadian" value="28" />
                      <DonorTrait label="German" value="29" />
                      <DonorTrait label="Greek" value="30" />
                      <DonorTrait label="Guatemalan" value="31" />
                      <DonorTrait label="Honduran" value="32" />
                      <DonorTrait label="Hungarian" value="33" />
                      <DonorTrait label="Icelandic" value="34" />
                      <DonorTrait label="Iranian" value="35" />
                      <DonorTrait label="Irish" value="36" />
                      <DonorTrait label="Israeli" value="37" />
                      <DonorTrait label="Italian" value="38" />
                      <DonorTrait label="Jamaican" value="39" />
                      <DonorTrait label="Japanese" value="40" />
                      <DonorTrait label="Jewish" value="41" />
                      <DonorTrait label="Korean" value="42" />
                      <DonorTrait label="Lebanese" value="43" />
                      <DonorTrait label="Mexican" value="44" />
                      <DonorTrait label="Moroccan" value="45" />
                      <DonorTrait label="Native American" value="46" />
                      <DonorTrait label="Nicaraguan" value="47" />
                      <DonorTrait label="Norwegian" value="48" />
                      <DonorTrait label="Pacific Islander" value="49" />
                      <DonorTrait label="Pakistani" value="50" />
                      <DonorTrait label="Palestinian" value="51" />
                      <DonorTrait label="Panamanian" value="52" />
                      <DonorTrait label="Paraguayan" value="53" />
                      <DonorTrait label="Persian" value="54" />
                      <DonorTrait label="Polish" value="55" />
                      <DonorTrait label="Portuguese" value="56" />
                      <DonorTrait label="Puerto Rican" value="57" />
                      <DonorTrait label="Romanian" value="58" />
                      <DonorTrait label="Russian" value="59" />
                      <DonorTrait label="Scandinavian" value="60" />
                      <DonorTrait label="Scottish" value="61" />
                      <DonorTrait label="Slavic" value="62" />
                      <DonorTrait label="Slovakian" value="63" />
                      <DonorTrait label="South African" value="64" />
                      <DonorTrait label="Spanish" value="65" />
                      <DonorTrait label="Swedish" value="66" />
                      <DonorTrait label="Swiss" value="67" />
                      <DonorTrait label="Taiwanese" value="68" />
                      <DonorTrait label="Thai" value="69" />
                      <DonorTrait label="Turkish" value="70" />
                      <DonorTrait label="Ukrainian" value="71" />
                      <DonorTrait label="Uruguayan" value="72" />
                      <DonorTrait label="Venezuelan" value="73" />
                      <DonorTrait label="Vietnamese" value="74" />
                      <DonorTrait label="Welsh" value="75" />
                      <DonorTrait label="Yugoslavian" value="76" />
                      <DonorTrait label="Zairian" value="77" />
                    </DonorTraitSelector>
                    <DonorTraitSelector label="CMV Status" trait="cmv_status">
                      <DonorTrait label="Positive" value="1" />
                      <DonorTrait label="Negative" value="2" />
                    </DonorTraitSelector>
                    <span className="toggleOptions" onClick={() => setEggAvailabilityShowMore(false)}>
                      Less Options
                    </span>
                  </>
                )}
                {!eggAvailabilityShowMore && (
                  <span className="toggleOptions" onClick={() => setEggAvailabilityShowMore(true)}>
                    More Options
                  </span>
                )}
              </>
            )}
          </div>
        </>
      )}

      {isMobile && (
        <div>
          {lookupFilters.length === 0 && (
            <>
              {!editingFilters && (
                <Button className="button--full-width" onClick={() => setEditingFilters(true)}>
                  Filter by Traits
                </Button>
              )}
              {editingFilters && (
                <Button buttonType="primary-alt" onClick={() => setEditingFilters(false)}>
                  Cancel
                </Button>
              )}
            </>
          )}
          {lookupFilters.length > 0 && (
            <div className="button-group-row button-group">
              <Button className="button--size--medium" onClick={() => setEditingFilters(!editingFilters)}>
                {editingFilters ? 'Apply' : 'Edit'} Filters
              </Button>
              <Button buttonType="primary-alt" className="button--size--medium" onClick={clearFilters}>
                Clear Filters
              </Button>
            </div>
          )}
        </div>
      )}

      <Modal
        closeButton={
          <span role="img" aria-label="Close Modal">
            <Close size={40} />
          </span>
        }
        isVisible={showOptionTypesModal}
        onClose={() => dispatch(setShowOptionTypesModal(false))}
      >
        <h3 className="modal__header">
          <InfoCircle size={28} fill={colors.brandBlue} />
          Types
        </h3>
        <div className="modal__content">
          <h4 className="subheader">
            <Ivf size={30} /> IVF
          </h4>
          <p>
            These vials are prepared for IVF insemination. They contain approximately 5 million motile sperm cells total.
          </p>
          <h4 className="subheader">
            <Icsi size={30} /> ICSI
          </h4>
          <p>
            These vials are ready to be used for ICSI insemination procedures.  They contain approximately 1 million motile sperm cells total.
          </p>
          <h4 className="subheader">
            <Iui size={30} /> IUI
          </h4>
          <p>
            These vials are prepared to be used for IUI procedures without further processing.  They contain approximately 10 million motile sperm cells total.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default DonorTraits;
