import React from 'react';
import { Link } from 'react-router-dom';
import TwebLogoWhite from '../../common/components/logo/logo-white';
import Star from '../../common/components/icons/star';
import LeftArrow from '../../common/components/icons/left-arrow';

const Auth = ({ children }) => {
  return (
    <div className="auth-layout">
      <div className="auth-layout__left">
        <div>
          <TwebLogoWhite />
        </div>
        <div className="auth-layout__left__testimonials">
          <div>
            <div className="auth-layout__left__testimonials__stars">
              <Star size={36} />
              <Star size={36} />
              <Star size={36} />
              <Star size={36} />
              <Star size={36} />
            </div>
            <p className="auth-layout__left__testimonials__quote">
              The World Egg & Sperm Bank took such good care of our donor. It was important to us that she understood we wanted
              the best for her.
            </p>
            <p className="auth-layout__left__testimonials__author">- Janet & Mark</p>
          </div>
        </div>
      </div>
      <div className="auth-layout__right">
        <div>
          <a className="button--text button--has-icon" href="https://theworldeggbank.com" rel="noopener noreferrer">
            <LeftArrow /> Back to Main Website
          </a>
        </div>
        <div className="auth-layout__right__children">{children}</div>
        <div className="auth-layout__footer">
          <a href="https://www.theworldeggandspermbank.com/privacy-policy/"
            rel="noopener noreferrer" target="_blank">
            Privacy Policy
          </a>
          &nbsp;|&nbsp;
          <a href="https://www.theworldeggandspermbank.com/accessibility-statement/"
            rel="noopener noreferrer" target="_blank">
            Accessibility Statement
          </a>
          &nbsp;|&nbsp;
          <a href="https://www.theworldeggandspermbank.com/contact-us/" target="_blank">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default Auth;
