import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { typography, spacing } from '../../app-theme';
import StyledButton from '../../common/components/buttons/button';
import Input from '../../common/forms/Input';
import { forgotPassword, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE } from '../../redux/user-actions';
import * as Yup from 'yup';
const StyledInput = Input as any;

const styles = {
    h2: {
        ...typography.displayL,
        marginBottom: spacing.u4,
    },
};

const SCHEMA = Yup.object().shape({
    email: Yup.string()
        .trim()
        .email()
        .required('Please enter your email'),
});

export const ForgotPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <>
            <div>
                <div className="auth-layout__form-container">
                    <h2 style={styles.h2}>Forgot your password?</h2>
                    <Formik
                        initialValues={{ email: '' }}
                        onSubmit={async (values, {setErrors}) => {
                            const {type, response} = await dispatch(forgotPassword(values.email));
                            if (type === FORGOT_PASSWORD_SUCCESS) {
                                return history.push('/login');
                            } else if (type === FORGOT_PASSWORD_FAILURE && response.status === 400) {
                                setErrors({email: 'No user found for provided email'});
                            }
                            return;
                        }}
                        validationSchema={SCHEMA}
                    >
                        {({ isSubmitting, isValid }) => (
                            <Form>
                                <StyledInput disabled={isSubmitting} id="email" name="email" label="Email" placeholder="Email" type="email" rounded />
                                <p className="margin-bottom__l">
                                    You will receive a password reset email.
                                </p>
                                <StyledButton type="submit" buttonType="primary" disabled={isSubmitting || !isValid}>
                                    Submit
                                </StyledButton>
                            </Form>
                        )}
                    </Formik>
                    <p className="margin-top__s">
                        <Link to="/login">Back to login</Link>
                    </p>
                </div>
            </div>
        </>
    );
};
