/* eslint-disable no-restricted-globals */

// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import URLSearchParams from '@ungap/url-search-params';

import { Provider } from 'react-redux';
import { compose, createStore, applyMiddleware } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import store from 'store2';
import thunk from 'redux-thunk';
import { createPlugin } from '@synapsestudios/fetch-client-goalie';

import { version } from '../package.json';
import apiRequestMiddleware from './common/utils/apiRequestMiddleware';
import reducers from './redux/reducers';
// import { LOGIN_USER_SUCCESS } from './redux/user-actions';
import App from './App';
import client from './client';
import './index.css';
import { composeWithDevTools } from 'redux-devtools-extension';

import config from './config';

// Polyfill URLSearchParams so fetch-client works in IE
window.URLSearchParams = URLSearchParams;

// Apply Redux middleware
let storeEnhancer = compose(responsiveStoreEnhancer, applyMiddleware(thunk, apiRequestMiddleware));

// Apply Redux DevTools in development only
if (process.env.NODE_ENV === 'development') {
    storeEnhancer = composeWithDevTools(storeEnhancer);
}

// Bootstrap the React app
const reduxStore = storeEnhancer(createStore)(reducers);

if (window.location.hash) {
    // Parse URL hash of format #foo=bar&stuff=things
    const hashQuery = window.location.hash
        .substring(1)
        .split('&')
        .map((str) => str.split('='))
        .reduce((prev, curr) => {
            prev[curr[0]] = curr[1];
            return prev;
        }, {});
    const nonce = store.get('nonce');

    if (hashQuery.id_token && nonce) {
        let decoded = JSON.parse(atob(hashQuery.id_token.split('.')[1]));

        if (nonce === decoded.nonce) {
            store.set('code', hashQuery.code);
            store.set(config.auth.idTokenKey, hashQuery.id_token);
            store.remove('nonce');
            // reduxStore.dispatch({ type: LOGIN_USER_SUCCESS });
        }

        // Remove URL hash while maintaining history and query string
        history.replaceState('', document.title, window.location.pathname + window.location.search);
    }
}

/**
 * We have to add the goalie plugin here because
 * importing store from inside the client is a
 * circular dependency
 */
client.addPlugin(createPlugin(`^${version}`, reduxStore));

ReactDOM.render(
    <Provider store={reduxStore}>
        <BrowserRouter>
            <div>
                <Route component={App} />
            </div>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
);
