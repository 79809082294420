import React from 'react';
import Svg, { Path } from 'svgs';
import { colors } from '../../../app-theme';

const Ivf = ({ size, fill }) => {
  return (
    <Svg height={size || 28} width={size || 28} viewBox="0 0 128.79 112.59" xmlns="http://www.w3.org/2000/svg" fill="none" stroke={fill || colors.brandBlue} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
        <Path d="M73.51,76.04c5.32,2.23,11.21,3.32,17.36,2.94,21.38-1.3,37.65-19.69,36.35-41.07C125.91,16.55,107.53,.27,86.15,1.57c-21.38,1.3-37.65,19.69-36.35,41.07,.17,2.82,.65,5.56,1.39,8.17"/>
        <Path d="M78.44,60.44c3.44,1.7,7.35,2.56,11.46,2.31,12.58-.77,22.16-11.59,21.39-24.17-.77-12.58-11.59-22.16-24.17-21.39-12.58,.77-22.16,11.59-21.39,24.17,.15,2.5,.72,4.88,1.6,7.09"/>
        <Path d="M53.8,73.85c1.71,.08,5.64,.04,9.65-2.35,3.81-2.27,5.57-5.39,5.95-6.1,1.01-1.88,2.86-5.3,1.55-7.29-1.89-2.88-9.52-1.36-14.04,1.06,0,0-3.08,1.65-6.55,5.74-.17,.2-.88,1.05-1.42,2.34-.34,.82-.68,1.66-.42,2.63,.16,.62,.5,1.06,.74,1.32-1.3,.91-2.65,1.77-4.12,2.37-1.82,.75-3.79,1.07-5.71,1.51-4.23,.96-8.5,2.59-11.4,5.82"/>
        <Path d="M36.77,100.18c1.53-.23,5.03-.98,8.17-3.83,2.99-2.71,3.99-5.81,4.2-6.5,.56-1.85,1.59-5.24,.07-6.77-2.2-2.22-8.72,.51-12.31,3.47,0,0-2.44,2.02-4.8,6.29-.12,.21-.59,1.09-.84,2.34-.16,.79-.3,1.6,.1,2.42,.26,.53,.64,.86,.89,1.04-1,1.04-2.04,2.05-3.24,2.85-1.49,.99-3.18,1.63-4.81,2.37-3.59,1.62-7.1,3.84-9.1,7.24"/>
        <Path d="M24.36,68.66c1.4,.42,4.68,1.2,8.51,.05,3.64-1.1,5.75-3.33,6.21-3.83,1.23-1.35,3.47-3.81,2.8-5.73-.98-2.78-7.63-3.1-11.88-2.03,0,0-2.9,.73-6.63,3.41-.18,.13-.95,.69-1.66,1.65-.45,.61-.91,1.24-.9,2.1,0,.55,.2,.99,.34,1.25-1.27,.48-2.57,.92-3.91,1.12-1.67,.24-3.37,.1-5.05,.07-3.71-.08-7.6,.39-10.68,2.47"/>
    </Svg>
  );
};

export default Ivf;
