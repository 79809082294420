import { fromJS } from 'immutable';

import { SET_LIGHTBOX_PHOTO_ID, SET_SHOW_OPTION_TYPES_MODAL } from './ui-actions';

export default (state = fromJS({ showOptionTypesModal: false }), action) => {
  if (action.type === SET_LIGHTBOX_PHOTO_ID) {
    return state.set('lightboxPhotoId', action.lightboxPhotoId);
  }

  if (action.type === SET_SHOW_OPTION_TYPES_MODAL) {
    return state.set('showOptionTypesModal', action.visible);
  }

  return state;
};
