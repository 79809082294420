import React, { Fragment, useMemo, useState } from 'react';
import { Map } from 'immutable';

import ChevronUp from '../../common/components/icons/chevron-up';
import ChevronDown from '../../common/components/icons/chevron-down';
import { colors } from '../../app-theme';

const DonorProfileOverview = ({ family }) => {
  const [grandparentsExpanded, setGrandparentsExpanded] = useState(true);
  const [parentsExpanded, setParentsExpanded] = useState(true);
  const [siblingsExpanded, setSiblingsExpanded] = useState(true);

  const [parents, siblings, grandparents] = useMemo(
    () => [
      [
        family
          .find(
            relation => relation.get('relationship_to_donor') === 'Father',
            null,
            new Map({ relationship_to_donor: 'Father' })
          )
          .toJS(),
        family
          .find(
            relation => relation.get('relationship_to_donor') === 'Mother',
            null,
            new Map({ relationship_to_donor: 'Mother' })
          )
          .toJS(),
      ],
      family.filter(relation => relation.get('relationship_to_donor').includes('Sibling')).toJS(),
      [
        family
          .find(
            relation => relation.get('relationship_to_donor') === 'Maternal Grand Mother',
            null,
            new Map({ relationship_to_donor: 'Maternal Grand Mother' })
          )
          .toJS(),
        family
          .find(
            relation => relation.get('relationship_to_donor') === 'Paternal Grand Mother',
            null,
            new Map({ relationship_to_donor: 'Paternal Grand Mother' })
          )
          .toJS(),
        family
          .find(
            relation => relation.get('relationship_to_donor') === 'Maternal Grand Father',
            null,
            new Map({ relationship_to_donor: 'Maternal Grand Father' })
          )
          .toJS(),
        family
          .find(
            relation => relation.get('relationship_to_donor') === 'Paternal Grand Father',
            null,
            new Map({ relationship_to_donor: 'Paternal Grand Father' })
          )
          .toJS(),
      ],
    ],
    [family]
  );

  const hasGrandparents = grandparents.some(grandparent => grandparent.family_member_id);
  const hasParents = parents.some(parent => parent.family_member_id);

  return (
    <div>
      {hasParents && (
        <Fragment>
          <h5 onClick={() => setParentsExpanded(!parentsExpanded)}>
            Parents
            {parentsExpanded ? (
              <ChevronUp size={20} fill={colors.brandBlue} />
            ) : (
              <ChevronDown size={20} fill={colors.brandBlue} />
            )}
          </h5>
          {!parentsExpanded && <hr className="section-divider" />}
          {parentsExpanded && <FamilyMembersTable familyMembers={parents} />}
        </Fragment>
      )}
      {hasGrandparents && (
        <Fragment>
          <h5 onClick={() => setGrandparentsExpanded(!grandparentsExpanded)}>
            Grandparents
            {grandparentsExpanded ? (
              <ChevronUp size={20} fill={colors.brandBlue} />
            ) : (
              <ChevronDown size={20} fill={colors.brandBlue} />
            )}
          </h5>
          {!grandparentsExpanded && <hr className="section-divider" />}
          {grandparentsExpanded && <FamilyMembersTable familyMembers={grandparents} />}
        </Fragment>
      )}
      {Boolean(siblings.length) && (
        <Fragment>
          <h5 onClick={() => setSiblingsExpanded(!siblingsExpanded)}>
            Siblings
            {siblingsExpanded ? (
              <ChevronUp size={20} fill={colors.brandBlue} />
            ) : (
              <ChevronDown size={20} fill={colors.brandBlue} />
            )}
          </h5>
          {siblingsExpanded && <FamilyMembersTable familyMembers={siblings} />}
        </Fragment>
      )}
    </div>
  );
};

const FamilyMembersTable = ({ familyMembers }) => (
  <table className="table__vertical">
    <tbody>
      <tr className="table__vertical__main-header">
        <th style={{ width: '150px' }}>Relation</th>
        {familyMembers.map(({ relationship_to_donor, family_member_id }) => (
          <th className="table__vertical__main-header" key={family_member_id}>
            {relationship_to_donor}
          </th>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Age</th>
        {familyMembers.map(({ age, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{age || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Age at Death / Cause of Death</th>
        {familyMembers.map(({ age_at_death, relationship_to_donor, cause_of_death }) => (
          <td key={relationship_to_donor}>{(age_at_death && cause_of_death ? `${age_at_death} / ${cause_of_death}` : null) || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Height</th>
        {familyMembers.map(({ height, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{height || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Weight</th>
        {familyMembers.map(({ weight, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{weight || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Race</th>
        {familyMembers.map(({ ethnicity, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{ethnicity || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Eye Color</th>
        {familyMembers.map(({ eye_color, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{eye_color || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Hair Color</th>
        {familyMembers.map(({ hair_color, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{hair_color || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Skin Type</th>
        {familyMembers.map(({ skin_type, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{skin_type || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Skills</th>
        {familyMembers.map(({ skills, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{skills || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Occupation</th>
        {familyMembers.map(({ occupation, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{occupation || 'N/A'}</td>
        ))}
      </tr>
      <tr>
        <th style={{ width: '150px' }}>Education</th>
        {familyMembers.map(({ education_level, relationship_to_donor }) => (
          <td key={relationship_to_donor}>{education_level || 'N/A'}</td>
        ))}
      </tr>
    </tbody>
  </table>
);

export default DonorProfileOverview;
