import React, { useMemo } from 'react';

import DonorFavorite from './DonorFavorite';

import Search from '../../common/components/icons/search';
import { usePhotos, useQuery } from '../../common/utils/hooks';
import { colors } from '../../app-theme';

import OptionTypeIcon from '../../common/components/OptionTypeIcon';

const getBloodTypeText = (bloodType, bloodTypeRh) => {
  if (bloodType === 'Unknown') {
    return 'Blood Type Unknown';
  } else if (bloodTypeRh === 'Unknown') {
    return `Blood Type ${bloodType}`;
  } else {
    return `Blood Type ${bloodType} ${bloodTypeRh}`;
  }
};

const DonorListItem = ({ donor }) => {
  const [query, setQuery] = useQuery();
  const donorData = useMemo(() => donor.toJS(), [donor]);

  const {
    primary: { thumb },
  } = usePhotos(donor);

  return (
    <div className="donor-list-item" onClick={() => setQuery(query.set('profileId', donorData.donor_id))}>
      <div className="donor-list-item__profile-img">
        <div style={{ backgroundImage: `url(${thumb}), url(../../common/images/placeholder-egg.png)`, backgroundSize: 'contain' }} />
        {donorData.eggs_available_date && <span className="donor-status coming-soon">coming soon</span>}
        {!donorData.active && <span className="donor-status inactive">inactive</span>}
      </div>
      <div className="donor-list-item__details">
        <div className="donor-list-item__details__top">
          <h4>ID: {donorData.donor_id}</h4>
          <DonorFavorite donorId={donorData.donor_id} favorited={donorData.favorited} />
        </div>

        <div className="donor-list-item__details__mid">
          {donorData.ethnicity && <h5>{donorData.ethnicity}</h5>}
          {donorData.hair_color && <span>{donorData.hair_color} Hair</span>}
          {donorData.eye_color && <span>{donorData.eye_color} Eyes</span>}
          {donorData.height && <span>{donorData.height}</span>}
          {donorData.education_level_public && <span>{donorData.education_level_public}</span>}
        </div>
        <div className="donor-list-item__details__mid">
          {donorData.cmv_status && <span>CMV Status {donorData.cmv_status}</span>}
          {donorData.blood_type && <span>{getBloodTypeText(donorData.blood_type, donorData.blood_type_rh)}</span>}
        </div>
        <div className="donor-list-item__details__bottom">
          {donorData.ivf && (
            <div className="donor-list-item__charm">
              <OptionTypeIcon type="ivf" showName size={22} position="top" />
            </div>
          )}
          {donorData.icsi && (
            <div className="donor-list-item__charm">
              <OptionTypeIcon type="icsi" showName size={22} position="top" />
            </div>
          )}
          {donorData.iui && (
            <div className="donor-list-item__charm">
              <OptionTypeIcon type="iui" showName size={22} position="top" />
            </div>
          )}
          {donorData.active && (
            <div className="donor-list-item__view-button button button--has-icon button--size--small button--white">
              <Search size={20} fill={colors.brandBlue} />
              view
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DonorListItem;
