import React from 'react';
import LoginForm from './LoginForm';
import { typography, spacing } from '../../app-theme';

const Login = () => {
    return (
        <div>
            <div className="auth-layout__form-container">
                <h2 style={styles.h2}>Not all Sperm banks are created equal.</h2>
                <LoginForm />
            </div>
        </div>
    );
};

const styles = {
    h2: {
        ...typography.displayL,
        marginBottom: spacing.u4,
    },
    h3: {
        ...typography.displayS,
        marginBottom: spacing.u4,
        fontWeight: typography.regular,
    },
    h4: {
        ...typography.displayS,
        marginBottom: spacing.u1,
    },
};

export default Login;
