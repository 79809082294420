import { Iterable, fromJS } from 'immutable';

export const newQuery = query => {
  const newQuery = new URLSearchParams();

  const queryMap = query.toJS ? query : fromJS(query);

  queryMap.forEach((param, key) => {
    if (Iterable.isIterable(param)) {
      param.forEach(value => newQuery.append(key, value));
    } else {
      newQuery.append(key, param);
    }
  });

  return newQuery;
};
