import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { fromJS, List, Map } from 'immutable';

import { newQuery } from './query-helpers';
import config from '../../config';
import placeholder from '../images/placeholder-egg.png';
import placeholderThumb from '../images/placeholder-egg-thumb.png';

// Taken from https://usehooks.com/useEventListener/
export const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = event => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

export const usePhotos = donor => {
  return useMemo(() => {
    const primary = donor.get('primary_photo');
    const largeThumb = `lgThumb-${donor.get('primary_photo')}`;
    const thumb = `thumb-${donor.get('primary_photo')}`;
    let photos = donor
      .get('photos', new List())
      .sortBy(photo => photo.get('sequence'))
      .map(
        photo =>
          new Map({
            full: `${config.imageUrl}/${photo.get('key')}`,
            id: photo.get('photo_id'),
            primary: photo.get('primary'),
            thumb: `${config.imageUrl}/${photo.get('thumb_key')}`,
          })
      );

    if (!photos.size) {
      photos = photos.push(
        new Map({
          full: placeholder,
          id: 'placeholder',
          primary: false,
          thumb: placeholderThumb,
        })
      );
    }

    return {
      primary: {
        full: primary ? `${config.imageUrl}/${primary}` : placeholder,
        large: primary ? `${config.imageUrl}/${largeThumb}` : placeholder,
        index: photos.findIndex(photo => photo.get('primary')),
        thumb: primary ? `${config.imageUrl}/${thumb}` : placeholderThumb,
      },
      photos,
    };
  }, [donor]);
};

export const useQuery = initialSearch => {
  const history = useHistory();
  const { search } = useLocation();

  const setQuery = useCallback(
    (query, options = {}, mode = 'push') => {
      const search = newQuery(query);

      history[mode]({ ...options, search: `?${search}` });
    },
    [history]
  );

  const searchString = search || initialSearch;

  return useMemo(() => {
    const query = new URLSearchParams(searchString);

    const values = {};

    if (!query.entries().next().done) {
        for (const [key, value] of query.entries()) {
            if (values[key]) {
                if (Array.isArray(values[key])) {
                    values[key].push(value);
                } else {
                    values[key] = [values[key], value];
                }
            } else {
                values[key] = value;
            }
        }
    }

    return [fromJS(values), setQuery];
  }, [searchString, setQuery]);
};
