import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import store from 'store2';
import cn from 'classnames';

import TwebLogoWhite from '../../common/components/logo/logo-white';
import Favorite from '../../common/components/icons/favorite';
import MobileMenu from '../../common/components/icons/menu';
import Close from '../../common/components/icons/close';
import HoverNavMenu from '../../common/components/HoverNavMenu';

import { getChangePasswordUrl, getEmailSettingsUrl } from '../../common/utils/oidc-url-builders';

const Main = ({ children }) => {
    const [deleted, loggedIn, favorites] = useSelector(
        ({ user }) => [user.getIn(['data', 'deleted']), user.get('loggedIn'), user.getIn(['data', 'favorites'])],
        shallowEqual,
    );
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const loginPath = store.get('loginPath');
        const loginSearch = store.get('loginSearch');

        if (loginPath || loginSearch) {
            store.remove('loginPath');
            store.remove('loginSearch');

            if (location.pathname !== '/contact') {
                history.replace(`${loginPath}${loginSearch}`);
            }
        }
    }, [history, location, loggedIn]);

    let favoritesString = Math.min(99, favorites).toString();

    if (favorites > 99) {
        favoritesString += '+';
    }

    const isMobile = useSelector(({ browser }) => browser.lessThan.large);
    const [mobileNavExpanded, setMobileNavExpanded] = useState(false);

    return (
        <div className={cn('main-layout', { hasMenuOpen: mobileNavExpanded })}>
            <div className={cn('header', { hasMenuOpen: mobileNavExpanded })}>
                <div className="header__wrapper">
                    <div>
                        <div className="header__branding">
                            <Link to="/">
                                <TwebLogoWhite width={216} />
                            </Link>
                        </div>
                        {isMobile && (
                            <div onClick={() => setMobileNavExpanded(!mobileNavExpanded)}>
                                {mobileNavExpanded ? <Close size={28} /> : <MobileMenu size={28} />}
                            </div>
                        )}
                    </div>
                    {(!isMobile || mobileNavExpanded) && (
                        <nav className={cn({ header__navigation: !isMobile, mobile__navigation: isMobile })}>
                            <ul>
                                {loggedIn && !deleted && (
                                    <>
                                        <li>
                                            <NavLink to="/favorites" onClick={() => isMobile && setMobileNavExpanded(false)}>
                                                My Favorites{' '}
                                                <span style={{ position: 'relative' }}>
                                                    <Favorite size={28} />
                                                    {favorites > 0 && (
                                                        <span className={cn('count-bubble', { max: favorites > 99 })}>{favoritesString}</span>
                                                    )}
                                                </span>
                                            </NavLink>
                                        </li>
                                        {!isMobile && (
                                            <li>
                                                <HoverNavMenu label="Account">
                                                    <a href={getEmailSettingsUrl()}>Change Email</a>
                                                    <a href={getChangePasswordUrl()}>Change Password</a>
                                                </HoverNavMenu>
                                            </li>
                                        )}

                                        {isMobile && (
                                            <>
                                                <li>
                                                    <a href={getEmailSettingsUrl()}>Change Email</a>
                                                </li>
                                                <li>
                                                    <a href={getChangePasswordUrl()}>Change Password</a>
                                                </li>
                                            </>
                                        )}
                                    </>
                                )}
                                <li>
                                    <NavLink to="/contact" onClick={() => isMobile && setMobileNavExpanded(false)}>
                                        Contact Us
                                    </NavLink>
                                </li>
                                <li>
                                    <a href="https://theworldeggbank.com">Return to Main Site</a>
                                </li>
                                {loggedIn && (
                                    <li>
                                        <NavLink to="/logout" onClick={() => isMobile && setMobileNavExpanded(false)}>
                                            Logout
                                        </NavLink>
                                    </li>
                                )}
                                {!loggedIn && (
                                    <li>
                                        <NavLink
                                            className="button"
                                            to="/login"
                                            onClick={() => {
                                                store.set('loginPath', location.pathname);
                                                store.set('loginSearch', location.search);
                                            }}
                                        >
                                            Log In
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    )}
                </div>
            </div>

            <div className="main-layout__wrapper">
                <div className="main-layout__container">{children}</div>
            </div>

            {/* Footer */}
            <div className="footer">
                <span>Copyright &copy; 2024 The World Egg and Sperm Bank, All Rights Reserved.</span>
            </div>
        </div>
    );
};

export default Main;
