import { Action } from 'redux';
import { DELETE_FAVORITE_SUCCESS, GET_FAVORITE_SUCCESS, POST_FAVORITE_SUCCESS } from './favorite-actions';

export interface IRecipientFavorite {
    Id: number;
    RecipientUserId: number;
    DonorId: number;
    Notes?: string;
}

export const favoriteReducer = (
    state: { favorite: IRecipientFavorite } = { favorite: { Id: 0, RecipientUserId: 0, DonorId: 0 } },
    action: Action & any,
) => {
    switch (action.type) {
        case GET_FAVORITE_SUCCESS:
            state.favorite = action.json;
            return { ...state };
        case POST_FAVORITE_SUCCESS:
            state.favorite = action.json;
            return { ...state };
        case DELETE_FAVORITE_SUCCESS:
            state.favorite = action.json;
            return { ...state };
        default:
            return state;
    }
};
